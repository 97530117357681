<template lang="pug">
nav.pagination(v-if="totalPages")
	//- router-link(
	//-   :to="{ query: { ...$route.query, page: 1 } }",
	//-   tag="li",
	//-   @click="changePage(1)",
	//-   v-if="displayFirstLastText"
	//- )
	//-   span {{ $t('pagination.first') }}
	template(v-if="visiblePages < totalPages")
		span.dots(v-if="currentPageNumber > Math.ceil(visiblePages / 2)") ...
	ul.pagination-list
		li(
			v-for="(n, index) in paginationRange",
			:key="index",
			:class="{ 'is-current': activePage(n) }",
			@click.prevent="completePageChange(n)"
		)
			a.pagination-link {{ n }}
	template(v-if="visiblePages < totalPages")
		span.dots(
			v-if="currentPageNumber < totalPages - Math.floor(visiblePages / 2)"
		) ...
	//- router-link(
	//-   :to="{ query: { ...$route.query, page: lastPage } }",
	//-   tag="li",
	//-   @click="changePage(lastPage)",
	//-   v-if="displayFirstLastText"
	//- )
	//-   span {{ $t('pagination.last') }}
</template>
<script>
export default {
	name: "PaginationWrap",
	props: {
		currentPage: {
			type: [String, Number],
			default: 1,
		},
		totalPages: Number,
		itemsPerPage: Number,
		totalItems: Number,
		// displayFirstLastText: {
		//   type: Boolean,
		//   default: true
		// },
		visiblePages: {
			type: Number,
			default: 5,
		},
	},
	computed: {
		currentPageNumber() {
			return parseInt(this.currentPage, 10);
		},
		lastPage() {
			if (this.totalPages) {
				return this.totalPages;
			} else {
				return this.totalItems % this.itemsPerPage === 0
					? this.totalItems / this.itemsPerPage
					: Math.floor(this.totalItems / this.itemsPerPage) + 1; // we add one, for that last page with incomplete itemsPerPage set.
			}
		},
		paginationRange() {
			const start =
				this.currentPageNumber - this.visiblePages / 2 <= 0
					? 1
					: // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
					this.currentPageNumber + this.visiblePages / 2 > this.lastPage
					? this.lowerBound(this.lastPage - this.visiblePages + 1, 1)
					: Math.ceil(this.currentPageNumber - this.visiblePages / 2);

			const range = [];

			for (let i = 0; i < this.visiblePages && i < this.lastPage; i++) {
				range.push(start + i);
			}

			return range;
		},
	},
	mounted() {
		const alreadyPage = parseInt(this.$route.query.page, 10);
		if (alreadyPage) {
			this.completePageChange(alreadyPage);
		}
	},
	methods: {
		lowerBound(num, limit) {
			return num >= limit ? num : limit;
		},
		activePage(pageNum) {
			return this.currentPageNumber === pageNum ? "is-current" : "";
		},
		changePage(pageNum) {
			const isSamePage = this.currentPageNumber === pageNum;
			if (!isSamePage) {
				this.$emit("page-changed", pageNum);
				return true;
			}
			return false;
		},
		completePageChange(pageNum) {
			const isChanged = this.changePage(pageNum);
			if (isChanged) {
				// eslint-disable-next-line eqeqeq
				const isSameRoute = this.$route.query.page == pageNum; // String and integers check
				if (!isSameRoute) {
					const currentQuery = this.$route.query;
					this.$router.replace({ query: { ...currentQuery, page: pageNum } });
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
$accent-color: rgb(52, 105, 123);

.pagination {
	display: inline-flex;
	align-items: center;
	margin: 0;
	gap: 0 10px;

	.dots {
		color: $accent-color;
		cursor: default;
	}

	.pagination-list {
		justify-content: flex-end;
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		column-gap: 10px;
	}

	li {
		$item-size: 30px;

		margin: 0;
		min-width: $item-size;
		height: $item-size;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $accent-color;
		cursor: pointer;
		font-weight: 600;

		&.is-current {
			border: 1px solid rgba(192, 192, 192, 0.226);
			border-radius: 10px;
			background: $dark-gray;
		}
	}

	.pagination-link {
		border-radius: 0;
	}
}
</style>
