import tutorialThumb from "@/assets/image/profile/tutorial/editorTutorial.png";

export default {
	config: {
		tutorialThumb,
	},
	steps: [
		{
			target: ".editor-view > .sidebar-wrap",
			header: {
				title: "Editor File Tree",
			},
			content:
				"This section lists all your files and folders that are in your project",
			params: {
				enableScrolling: false,
				placement: "right",
			},
		},
		// {
		//     target: '.editor-view .action-btns',
		//     header: {
		//         title: 'Editor actions',
		//     },
		//     content: 'You can create a new file and folder here, or run your site',
		//     params: {
		//         enableScrolling: false,
		//         placement: 'bottom',
		//     },
		// }
	],
};
