import Command from "../AbstractCommand";

/*
    parameters: {
      tableName: String,
      foreignKeyConstraintName: String
    }
 */

class DropForeignKeyCommand extends Command {
	constructor(foreignKey, table) {
		super("DropForeignKey");
		this.tableName = table.name;
		this.foreignKeyName = foreignKey.constraintName;
	}

	execute() {
		return `ALTER TABLE \`${this.tableName}\` DROP FOREIGN KEY \`${this.foreignKeyName}\``;
	}
}

export default DropForeignKeyCommand;
