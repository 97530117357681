const routes = {
	namespace: "sandbox",
	routes: [
		{
			path: "",
			name: "SandboxPage",
			component: () => import("./SandboxPage.vue"),
			children: [],
		},
		{
			path: "/editor",
			name: "SandboxEditor",
			component: () => import("@/modules/editor/components/Editor.vue"),
		},
		{
			path: "/ed",
			name: "SandboxEditorComposition",
			component: () => import("@/modules/sandbox/SandboaxEditor.vue"),
		},
	],
};

export default routes;
