import { mapActions } from "vuex";

const mixins = {
	emits: ["logged-in"],
	methods: {
		...mapActions({
			authLogin: "moduleAuth/authLogin",
			registerApi: "moduleAuth/registerApi",
		}),
		validateEmail(email) {
			const re =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
		async registerUserApi(params) {
			try {
				const res = await this.registerApi(params);
				this.openToastSuccessMsg(res.data.message);
				console.log("Register", res.data);
				this.$router.push({
					name: "FinishRegister",
				});
			} catch (err) {
				const msg = err.response.data && err.response.data.message;
				this.openToastErrorMsg(msg);
			}
		},
		async loginUserApi(params) {
			try {
				const res = await this.authLogin(params);
				if (res.data.sid) {
					sessionStorage.sid = res.data.sid;
					sessionStorage.user = JSON.stringify(res.data.user);

					// Goto first page after login
					const payload = {
						res: res.data,
						form: this.form,
					};

					this.$emit("logged-in", payload);
				} else {
					// Show 2FA
					console.log(res.data);
					if (this.hasTwoFactor) {
						this.openToastErrorMsg(res?.data?.message);
					}
					this.hasTwoFactor = true;
				}
			} catch (err) {
				// console.warn(err.message)
				const errMessage = err?.response?.data?.message;
				this.errorMessage = errMessage;
				// this.showErrMessage(errMessage);
				this.openToastErrorMsg(errMessage);
				this.form.mfacode = undefined;
			}
		},
		// showErrMessage(errMessFromApi) {
		//   const params = {
		//     data: {
		//       type: "alert",
		//       text: errMessFromApi,
		//     },
		//     events: {
		//       onclose: () => {
		//         if (!this.hasTwoFactor) {
		//           this.form.pass = "";
		//           document.querySelector("#password")?.focus();
		//         }
		//       },
		//     },
		//   };
		//   this.openPopupStorage(params);
		// },
	},
};

export default mixins;
