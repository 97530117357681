<template>
	<div class="modal-wrapper">
		<div class="section">
			<input
				v-model="options.oldPass"
				class="flat flat-alt"
				type="password"
				placeholder="Old password"
				autocomplete="new-password"
				@keyup.enter="save"
			/>
		</div>
		<div class="section">
			<input
				v-model="options.newPass"
				class="flat flat-alt"
				type="password"
				placeholder="New password"
				autocomplete="new-password"
				@keyup.enter="save"
			/>
		</div>
		<div class="section">
			<input
				v-model="options.repeatPass"
				class="flat flat-alt"
				type="password"
				placeholder="Repeat password"
				autocomplete="new-password"
				@keyup.enter="save"
			/>
		</div>
		<div class="action-wrapper">
			<div class="btn trans" @click="cancel">Cancel</div>
			<div class="btn" @click="save">Save</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PasswordModal",
	props: ["options"],
	methods: {
		cancel() {
			this.options.newPass = "";
			this.options.oldPass = "";
			this.options.repeatPass = "";
			this.closeModernModalCompat();
		},
		save() {
			this.closeModernModalCompat();
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/adminModals";
.modal-wrapper {
	@include admin-modals-styles;

	padding-top: 20px;
}
.action-wrapper {
	display: flex;
	justify-content: flex-end;
	padding: 10px 0 20px;

	.btn {
		padding: 13px 34px;
	}

	.btn:first-child {
		margin-right: 10px;
	}
}
</style>
