import Command from "../AbstractCommand";

/* INTERFACE:
  parameters:{
    constraintName,
    table: "table name",
    columns: ["col1 name", "col2 name"],
    refTable: "referenced table name",
    refColumns: ["col1 name", "col2 name"],
    onUpdate,
    onDelete
  }
*/
class CreateForeignKeyCommand extends Command {
	constructor(foreignKey, table) {
		super("CreateForeignKey");

		this.foreignKey = {
			constraintName: foreignKey.constraintName,
			table: table.name,
			columns: foreignKey.columns,
			refTable: foreignKey.referencedTable.name,
			refColumns: foreignKey.referencedColumns,
			onUpdate: foreignKey.onUpdate,
			onDelete: foreignKey.onDelete,
		};
	}

	execute() {
		// Init ddl
		let ddl = `ALTER TABLE \`${this.foreignKey.table}\` \nADD CONSTRAINT \`${this.foreignKey.constraintName}\` FOREIGN KEY (`;

		// Columns
		for (let i = 0; i < this.foreignKey.columns.length; i++) {
			if (this.foreignKey.columns[i] && this.foreignKey.refColumns[i]) {
				ddl += `\`${this.foreignKey.columns[i]}\``;
				if (i !== this.foreignKey.columns.length - 1) ddl += ", ";
			}
		}
		ddl += ") ";

		// Referenced table
		ddl += `REFERENCES \`${this.foreignKey.refTable}\` (`;

		for (let i = 0; i < this.foreignKey.refColumns.length; i++) {
			if (this.foreignKey.refColumns[i] && this.foreignKey.columns[i]) {
				ddl += `\`${this.foreignKey.refColumns[i]}\``;
				if (i !== this.foreignKey.refColumns.length - 1) ddl += ", ";
			}
		}
		ddl += ")\n";

		// Actions
		let onUpdate = this.foreignKey.onUpdate,
			onDelete = this.foreignKey.onDelete;
		if (!onUpdate) onUpdate = "NO ACTION";
		if (!onDelete) onDelete = "NO ACTION";
		ddl += `ON UPDATE ${onUpdate} \nON DELETE ${onDelete}`;

		return ddl;
	}
}

export default CreateForeignKeyCommand;
