const routes = {
	namespace: "",
	routes: [
		{
			path: "/:pathMatch(.*)*",
			name: "NotFoundPage",
			redirect: "/",
		},
	],
};

export default routes;
