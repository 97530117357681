export function debounce(func, waitTimer, immediate) {
	let timeout = null;
	return function () {
		const context = this;
		const args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, waitTimer);
		if (callNow) func.apply(context, args);
	};
}

export function throttle(func, waitTimer = 500) {
	let inThrottle = false;
	return function () {
		const args = arguments;
		const context = this;
		if (!inThrottle) {
			func.apply(context, args);
			inThrottle = true;
			setTimeout(() => {
				inThrottle = false;
			}, waitTimer);
		}
	};
}

export function dataURLToBlob(dataURL) {
	const BASE64_MARKER = ";base64,";
	if (!dataURL.includes(BASE64_MARKER)) {
		const parts = dataURL.split(",");
		const contentType = parts[0].split(":")[1];
		const raw = parts[1];

		return new Blob([raw], { type: contentType });
	}

	const parts = dataURL.split(BASE64_MARKER);
	const contentType = parts[0].split(":")[1];
	const raw = window.atob(parts[1]);
	const rawLength = raw.length;

	const uInt8Array = new Uint8Array(rawLength);

	for (let i = 0; i < rawLength; ++i) {
		uInt8Array[i] = raw.charCodeAt(i);
	}

	return new Blob([uInt8Array], { type: contentType });
}

export function cleanObjectKeyVal(objectToParse, modifier = []) {
	// Reusable object trimmer and key-cleaner
	return Object.fromEntries(
		Object.entries(objectToParse).reduce((acc, [currKey, currVal]) => {
			let value = currVal || "";
			if (modifier.includes("trim")) {
				value = value.trim();
			}
			if (value) {
				const kvRow = [currKey, value];
				acc.push(kvRow);
			}
			return acc;
		}, []),
	);
}

export function sortListFileTree(list, debug = false) {
	// Needs name, type, id
	const compareFileNames = (a, b) => {
		if (debug) console.log("Name check >>", a.name, b.name);
		if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
		if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
		return 0;
	};

	// const compareProperFolders = (a, b) => {
	//   if (a.type === "FLD" && b.type !== "FLD") return -1;
	//   if (a.type !== "FLD" && b.type === "FLD") return 1;
	//   if (a.type === "FLD" && b.type === "FLD") return compareFileNames(a, b);
	//   return 0;
	// };

	// const compareVirtualFolders = (a, b) => {
	//   if (a.id === 0 && b.id !== 0) return -1;
	//   if (a.id !== 0 && b.id === 0) return 1;
	//   if (a.id === 0 && b.id === 0) return compareFileNames(a, b);
	//   return 0;
	// };

	const compareAllFolders = (a, b) => {
		if ((a.type === "FLD" || a.id === 0) && b.type !== "FLD" && b.id !== 0)
			return -1;
		if (a.type !== "FLD" && a.id !== 0 && (b.type === "FLD" || b.id === 0))
			return 1;
		if ((a.type === "FLD" || a.id === 0) && (b.type === "FLD" || b.id === 0))
			return compareFileNames(a, b);
		return 0;
	};

	return [...list].sort((first, second) => {
		// const resFld = compareProperFolders(first, second);
		// if (resFld !== 0) return resFld;

		// const resVirtual = compareVirtualFolders(first, second);
		// if (resVirtual !== 0) return resVirtual;

		const resFolder = compareAllFolders(first, second);
		if (resFolder !== 0) return resFolder;

		const resName = compareFileNames(first, second);
		return resName;
	});
}
