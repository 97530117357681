<script>
import { mapActions, mapGetters } from "vuex";
import SitesChecklist from "./SitesChecklist.vue";
import { infoConsole } from "@/assets/js/helpers";
export default {
	components: {
		SitesChecklist,
	},
	props: ["options"],
	data() {
		return {
			originalList: {},
			checkedList: {},
			form: {
				email: "",
			},
		};
	},
	computed: {
		...mapGetters({
			getProjectInfo: "moduleProject/getProjectInfo",
			getProjectSites: "moduleProject/getProjectSites",
			getSiteData: "moduleProject/getSiteData",
		}),
		isEditUser() {
			return this.options.row.usr_id;
		},
		canEditUser() {
			return Object.keys(this.checkedList).length;
		},
	},
	mounted() {
		if (this.options.row.sites) {
			const mappedIds = this.options.row.sites.map((site) => site.sit_id);
			for (const id of mappedIds) {
				this.originalList[id] = true;
				this.checkedList[id] = true;
			}
		}
	},
	methods: {
		...mapActions({
			putAddRemoveUsers: "putAddRemoveUsers",
		}),
		generateParams(checkKey, checkVal) {
			const site = this.getProjectSites.find(
				(site) => site.sit_id === parseInt(checkKey, 10),
			);

			if (this.options.row.usr_id) {
				return {
					user: {
						sit_id: site.sit_id,
						usr_id: this.options.row.usr_id,
						usr_email: this.options.row.usr_email,
						action: checkVal === true ? "add" : "remove",
					},
				};
			}

			return {
				user: {
					sit_id: site.sit_id,
					usr_email: this.form.email,
					action: "add",
				},
			};
		},
		async editUser() {
			if (!this.canEditUser) {
				infoConsole("Can't edit user", "warn");
				return;
			}

			let co = 0;
			const checkList = Object.entries(this.checkedList);
			infoConsole("CheckList", "warn", checkList);
			let hasSomeError = false;
			for (const [checkKey, checkVal] of checkList) {
				const isModified =
					!this.originalList[checkKey] ||
					this.originalList[checkKey] !== this.checkedList[checkKey];
				if (!isModified) {
					continue;
				}

				const params = this.generateParams(checkKey, checkVal);

				try {
					const res = await this.putAddRemoveUsers(params);
					infoConsole("Response add/remove users", "log", res.data);
					co++;
				} catch (err) {
					infoConsole(err.message, "warn");
					hasSomeError = true;
					break;
				}
			}

			if (hasSomeError) {
				if (this.isEditUser) {
					this.openToastErrorMsg("Error editing user");
				} else {
					this.openToastErrorMsg("Error adding user");
				}
			} else {
				if (this.isEditUser) {
					this.openToastSuccessMsg("Success editing user");
				} else {
					this.openToastSuccessMsg("User added successfully");
				}
				this.closeModernModalCompat();
			}
		},
	},
};
</script>

<template lang="pug">
.modal-wrapper
	.user-field(v-if="options.row.usr_id")
		.avatar
			user-avatar(
				:name="options.row.usr_name",
				:size="10",
				:userId="options.row.usr_id"
			)
		.name(title="Name")
			| {{ options.row.usr_name }}
		.email(title="email")
			| {{ options.row.usr_email }}
	.section.email(v-if="!isEditUser")
		input.input.rounded(v-model="form.email", placeholder="Email")
	sites-checklist(:checked-list="checkedList", @update="checkedList = $event")
	.actions
		button.btn.cancel.trans(@click="closeModernModalCompat") Cancel
		button.btn.apply(:disabled="!canEditUser", @click="editUser") Save
</template>

<style lang="scss" scoped>
.modal-wrapper {
	padding: 30px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	.user-field {
		display: flex;
		gap: 10px;
		align-items: center;
		color: #f2f2f2;
		font-weight: bold;

		.avatar {
			$size: 28px;

			height: $size;
			width: $size;
			max-width: $size; // For some older browsers
		}

		.email {
			margin-left: auto;
		}
	}

	.section.email {
		input {
			width: 100%;
		}
	}

	.actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 20px;
		margin-top: 10px;

		button {
			height: 55px;
		}
	}
}
</style>
