import type {
	TValidApiTypes,
	TValidWSTypes,
} from "@/modules/core/file-manager/fileManagerTS";

const methodList: {
	__isI18n: boolean;
	title: string;
	name: TValidApiTypes | "notes";
	image?: string;
}[] = [
	{
		__isI18n: true,
		title: "editor.editorHttpApiGet",
		name: "GET",
	},
	{
		__isI18n: true,
		title: "editor.editorHttpApiPost",
		name: "POST",
	},
	{
		__isI18n: true,
		title: "editor.editorHttpApiPut",
		name: "PUT",
	},
	{
		__isI18n: true,
		title: "editor.editorHttpApiDelete",
		name: "DELETE",
	},
	{
		__isI18n: true,
		title: "editor.editorHttpApiHead",
		name: "HEAD",
	},
	{
		__isI18n: true,
		title: "editor.editorHttpApiPatch",
		name: "PATCH",
	},
	{
		__isI18n: true,
		title: "editor.editorHttpApiOptions",
		name: "OPTIONS",
	},
	{
		__isI18n: true,
		title: "editor.editorSubTitleNotes",
		name: "notes",
		image: "editIcon",
	},
] as const;
const eventList: {
	__isI18n: boolean;
	title: string;
	name: TValidWSTypes | "notes";
	image?: string;
}[] = [
	{
		__isI18n: true,
		title: "editor.editorWebSocketOnOpen",
		name: "onconnect",
	},
	{
		__isI18n: true,
		title: "editor.editorWebSocketOnMessage",
		name: "onmessage",
	},
	{
		__isI18n: true,
		title: "editor.editorWebSocketOnClose",
		name: "ondisconnect",
	},
	{
		__isI18n: true,
		title: "editor.editorSubTitleNotes",
		name: "notes",
		image: "editIcon",
	},
] as const;

export { methodList, eventList };
