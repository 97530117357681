// import api from '@/api'

const actions = {};

const mutations = {
	EDIT_SITE(state, payload) {
		state.editingSite = payload;
	},
};

const state = {
	editingSite: null,
};
export default { namespaced: true, actions, mutations, state };
