import api from "@/api";
import editorThemeList from "@/modules/editor/editorThemeList";

const state = {
	editorSelectedTheme: {
		label: "Mars UI",
		name: "mars-ui",
	},
	defaultThemes: [
		{
			label: "VS Light",
			name: "vs",
		},
		{
			label: "VS Dark",
			name: "vs-dark",
		},
		{
			label: "Mars UI",
			name: "mars-ui",
		},
	],
	presetThemes: editorThemeList,
};

const getters = {
	getMonacoTheme(state) {
		return state.editorSelectedTheme?.name || "mars-ui";
	},
};

const actions = {
	getUserInfo(store) {
		return api.getUserInfo();
	},
	updateUserInfo(store, data) {
		return api.updateUserInfo(data);
	},
	toggleEditorTheme({ commit }, name) {
		commit("SET_EDITOR_THEME", name);
	},
};

const mutations = {
	SET_EDITOR_THEME(state, name) {
		const allThemes = state.defaultThemes.concat(state.presetThemes);
		const foundTheme = allThemes.find((theme) => theme.name === name);
		if (foundTheme) {
			state.editorSelectedTheme = foundTheme;
		}
	},
};
export default {
	namespaced: true,
	getters,
	actions,
	mutations,
	state,
};
