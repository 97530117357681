<template>
	<v-tour name="vueTour" :steps="getTourSteps" :options="tourOptions">
		<template #default="tour">
			<transition name="fade">
				<v-step
					v-if="tour.steps[currentStep]"
					:key="currentStep"
					:step="tour.steps[currentStep]"
					:previous-step="tour.previousStep"
					:next-step="tour.nextStep"
					:stop="tour.stop"
					:skip="tour.skip"
					:finish="tour.finish"
					:is-first="tour.isFirst"
					:is-last="tour.isLast"
					:labels="tour.labels"
					:enabled-buttons="tour.enabledButtons"
					:highlight="tour.highlight"
				>
					<template #actions>
						<div class="actions">
							<button
								v-if="!tour.isLast && isButtonEnabled('skip', tour)"
								@click.prevent="tourSkipMethod(tour)"
							>
								{{ labels.buttonSkip }}
							</button>
							<button
								v-if="!tour.isFirst && isButtonEnabled('previous', tour)"
								class="btn btn-primary"
								@click="tourPreviousStepMethod(tour)"
							>
								{{ labels.buttonPrevious }}
							</button>
							<button
								v-if="!tour.isLast && isButtonEnabled('next', tour)"
								class="btn btn-primary"
								@click="tourNextStepMethod(tour)"
							>
								{{ labels.buttonNext }}
							</button>
							<button
								v-if="tour.isLast && isButtonEnabled('finish', tour)"
								@click.prevent="tourFinishMethod(tour)"
							>
								{{ labels.buttonFinish }}
							</button>
						</div>
					</template>
				</v-step>
			</transition>
		</template>
	</v-tour>
</template>

<script>
import tourSteps from "@/script/tourSteps.js";
export default {
	props: {
		step: {
			type: [Number, null],
			required: false,
			default: null,
		},
		groupName: {
			type: String,
			required: true,
			validator(value) {
				return value?.length > 0;
			},
		},
		stepsName: {
			type: String,
			required: true,
			validator(value) {
				return value?.length > 0;
			},
		},
	},
	emits: [
		"element-click",
		"keydown-click",
		"tour-disabled",
		"tour-previous-step",
		"tour-next-step",
		"tour-stop",
		"tour-skip",
		"tour-finish",
	],
	data() {
		return {
			currentStep: -1,
			manualCurrentStep: null,
			lastStep: null,
			lastFuncStep: null,
			tourOptions: {
				debug: true,
				useKeyboardNavigation: false,
				enabledButtons: {
					buttonPrevious: false,
					buttonNext: false,
					buttonStop: false,
					buttonSkip: false,
				},
				highlight: true,
			},
			tourCallbacks: {
				onStart: this.tourStartMethod,
				onPreviousStep: this.tourPreviousStepMethod,
				onNextStep: this.tourNextStepMethod,
				onSkip: this.tourSkipMethod,
				onStop: this.tourFinishMethod,
			},
		};
	},
	computed: {
		tourIntroApiKey() {
			return this.transformUserConfigKey(
				this.$userConfig.tour.tourIntroDisplay,
				[this.groupName, this.stepsName],
			);
		},
		getTourSteps() {
			const steps = tourSteps[this.groupName]?.[this.stepsName]?.steps;
			if (steps) {
				return steps;
			}

			return [];
		},
		labels() {
			return {
				buttonSkip: this.$i18n.t("tutorial.tutorialButtonSkip"),
				buttonPrevious: this.$i18n.t("tutorial.tutorialButtonPrevious"),
				buttonNext: this.$i18n.t("tutorial.tutorialButtonNext"),
				buttonStop: this.$i18n.t("tutorial.tutorialButtonStop"),
				buttonFinish: this.$i18n.t("tutorial.tutorialButtonFinish"),
			};
		},
		tourInfo() {
			return {
				lastStep: this.lastStep,
				currentStep: this.manualCurrentStep,
				lastFuncStep: this.lastFuncStep,
				group: this.groupName,
				subGroup: this.stepsName,
			};
		},
	},
	watch: {
		$route(val) {
			// console.warn('changed', val);
			// this.checkActivateTour()
		},
		step(newStep, oldStep) {
			// console.warn(newStep, oldStep);

			const tempCurrentStep = this.currentStep;
			if (this.step < this.getTourSteps.length) {
				if (newStep === 0 && oldStep === -1) {
					// Start of new tour
					this.checkActivateTour();
				} else {
					this.currentStep = this.step;
				}
			} else {
				this.currentStep = -1;
				this.$tours.vueTour.stop();
				return;
			}

			// [Manual funcs to activate native]
			if (this.step === -1) {
				this.$tours.vueTour.stop();
			} else if (tempCurrentStep === this.step - 1) {
				// [Next]
				this.$tours.vueTour.nextStep();
				// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
			} else if (tempCurrentStep === this.step + 1) {
				// [Previous]
				this.$tours.vueTour.previousStep();
			}
		},
		currentStep() {
			// Manage click event
			this.clearElementKeyEvents();
			this.setElementKeyEvent();
		},
	},
	mounted() {
		// Blocks loading (starting) v-tour inside a modal
		// if (this.isModalShown) {
		// Stop if modal
		// this.currentStep = -1;
		// } else {
		this.checkActivateTour(this.step);
		this.setElementKeyEvent();
		// }
	},
	beforeUnmount() {
		this.$tours.vueTour.stop();
	},
	methods: {
		tourStartMethod(tour) {
			console.log("Start tour");
			this.changeSetStep(0, tour, "start");
		},
		tourPreviousStepMethod(tour) {
			const newVal = this.currentStep - 1;
			this.changeSetStep(newVal, tour, "previousStep");
		},
		tourNextStepMethod(tour) {
			const newVal = this.currentStep + 1;
			this.changeSetStep(newVal, tour, "nextStep");
		},
		tourSkipMethod(tour) {
			this.changeSetStep(-1, tour, "skip");
			this.disableTourConfig(false);
		},
		tourFinishMethod(tour) {
			this.changeSetStep(-1, tour, "finish");
			this.disableTourConfig(false);
		},
		disableTourConfig(isSet) {
			const param = this.mutateUserConfigArray(this.tourIntroApiKey, false);
			this.setUserConfig(param);
		},
		changeSetStep(num, tour, funcName) {
			this.lastStep = this.currentStep;
			this.lastFuncStep = funcName;

			if (this.step === -1) {
				// [NATIVE] Only set if step is undefined [-1]
				this.currentStep = num;
				tour[funcName]();
			}
			// [MANUAL]
			this.manualCurrentStep = num;
			this.$emit(`tour-${funcName}`, this.tourInfo);
		},
		getOneStepTour() {
			let index = this.currentStep;
			if (!(index >= 0)) {
				index = this.manualCurrentStep;
			}

			return {
				index,
				step: this.getTourSteps[index],
			};
		},
		async checkActivateTour(fromStep = 0) {
			const isEnabled = await this.checkIfTourEnabled();
			if (isEnabled === false || isEnabled === undefined) {
				this.$emit("tour-disabled", this.tourInfo);
				if (isEnabled === undefined) {
					console.log("<< Invalid login tour check >>");
				}
				// console.log('[Tour - Disabled] << ', this.tourIntroApiKey, isEnabled);
				return;
			}

			this.$nextTick(() => {
				if (this.$tours?.myTour) {
					// console.log('[Tour - Start] >> ', this.tourIntroApiKey);
					// this.$tours.myTour.start();
					this.$tours.vueTour.start();
					this.currentStep = fromStep ?? 0;
				}
			});
		},
		setElementKeyEvent() {
			const { step } = this.getOneStepTour();

			if (step?.target) {
				const stepElement = document.querySelector(step.target);
				if (stepElement) {
					stepElement.removeEventListener(
						"keydown",
						this.stepElKeyDownEventHandler,
					);
					stepElement.removeEventListener(
						"mouseup",
						this.stepElMouseUpEventHandler,
					);
					// Keydown listener needs to listen always, unlike mouse click;
					stepElement.addEventListener(
						"keydown",
						this.stepElKeyDownEventHandler,
					);
					stepElement.addEventListener(
						"mouseup",
						this.stepElMouseUpEventHandler,
						{ once: true },
					);
				}
			}
		},
		clearElementKeyEvents() {
			const { step } = this.getOneStepTour();

			if (step?.target) {
				const stepElement = document.querySelector(step.target);
				if (stepElement) {
					stepElement.removeEventListener(
						"keydown",
						this.stepElKeyDownEventHandler,
					);
					stepElement.removeEventListener(
						"mouseup",
						this.stepElMouseUpEventHandler,
					);
				}
			}
		},
		packParams(evt) {
			const { step, index } = this.getOneStepTour();

			return {
				group: this.groupName,
				subGroup: this.stepsName,
				stepId: index,
				step,
				evt,
			};
		},
		stepElMouseUpEventHandler(evt) {
			const params = this.packParams(evt);
			this.$emit("element-click", params);
		},
		stepElKeyDownEventHandler(evt) {
			const params = this.packParams(evt);
			this.$emit("keydown-click", params);
		},
		isButtonEnabled(name, tour) {
			if (tour.steps[this.currentStep]?.buttons?.[name] === false) {
				return false;
			}
			return true;
		},
		checkIfTourEnabled() {
			return this.getUserConfig(this.tourIntroApiKey);
		},
	},
};
</script>

<style lang="scss" scoped>
.v-tour {
	.actions {
		> * {
			margin-right: 5px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
</style>
<style lang="scss">
.v-tour__target--highlighted {
	//   box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
	box-shadow: none !important;
	border: 2px solid yellow !important;
}
</style>
