export default {
	global: {
		userWhatsNew: "user-whatsnew-app-ver",
	},
	project: {
		recentList: "projects-user-recent",
		projectListView: "project-view-is-list",
	},
	editor: {
		apiSettingSidebar: "editor-api-settings-tab",
		editorProjectOpenTabs: "editor-proj%0-tabPathArr",
		editorProjectTabLastUsed: "editor-proj%0-tabPathLastUsed",
	},
	tour: {
		tourIntroDisplay: "tour-intro-display-%0-%1",
	},
	// Add others below ==
	// import: {},
	// recovery: {},
	// settings: {},
	// errors: {},
	// database: {},
	profile: {
		editor: {
			theme: "profile-editor-theme",
			font: "profile-editor-font",
			other: "profile-editor-other-settings",
		},
	},
};
