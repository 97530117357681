import { baseURL } from "@/api/config";
import { type RemovableRef, useStorage } from "@vueuse/core";
import { type JSZipMetadata } from "jszip";
import type JSZip from "jszip";

// @ts-expect-error
export function debounce(func, waitTimer, immediate) {
	// @ts-expect-error
	let timeout = null;
	return function () {
		// @ts-expect-error
		// eslint-disable-next-line @typescript-eslint/no-this-alias, unicorn/no-this-assignment
		const context = this;
		// eslint-disable-next-line prefer-rest-params
		const args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		// @ts-expect-error
		const callNow = immediate && !timeout;
		// @ts-expect-error
		clearTimeout(timeout);
		timeout = setTimeout(later, waitTimer);
		if (callNow) func.apply(context, args);
	};
}

// @ts-expect-error
export function throttle(func, waitTimer = 500) {
	let inThrottle = false;
	return function () {
		// eslint-disable-next-line prefer-rest-params
		const args = arguments;
		// @ts-expect-error
		// eslint-disable-next-line @typescript-eslint/no-this-alias, unicorn/no-this-assignment
		const context = this;
		if (!inThrottle) {
			func.apply(context, args);
			inThrottle = true;
			setTimeout(() => {
				inThrottle = false;
			}, waitTimer);
		}
	};
}

export function focusFirstElement(
	parentEl: HTMLElement,
	isFocus = true,
	isDebug = false,
) {
	// Don't invoke more than once (Before a component is destroyed)
	if (parentEl) {
		const inputListToIncludeFocusable = [
			"input:not(:disabled):not(.hidden)",
			"textarea:not(:disabled):not(.hidden)",
			"button:not(:disabled):not(.hidden)",
			"span.focusable",
			"div.focusable",
			".multiselect[tabindex]:not([tabindex='-1'])",
			"*[tabindex]:not(:disabled):not(.hidden):not([tabindex='-1'])",
		].join(",");

		const nodeList = Array.from(
			parentEl.querySelectorAll(inputListToIncludeFocusable),
		) as HTMLElement[];
		if (nodeList?.length) {
			const addInputTabHandling = (nodeList: HTMLElement[]) => {
				const focusEl = (evt: KeyboardEvent, oppoEl: HTMLElement) => {
					// Only for first / last element
					oppoEl.focus();
					evt.preventDefault();
				};

				// First el
				nodeList[0].addEventListener("keydown", (evt: KeyboardEvent) => {
					if (evt.key === "Tab" && evt.shiftKey) {
						focusEl(evt, nodeList[nodeList.length - 1]);
					}
				});

				// Last el
				nodeList[nodeList.length - 1].addEventListener(
					"keydown",
					(evt: KeyboardEvent) => {
						if (evt.key === "Tab" && !evt.shiftKey) {
							focusEl(evt, nodeList[0]);
						}
					},
				);
			};

			if (isFocus) {
				// console.log("➕ Focusing first el", nodeList[0]);
				nodeList[0].focus();

				if (isDebug) {
					console.error("➕ Focusing first el", nodeList[0]);
				}
			}

			addInputTabHandling(nodeList);
		} else if (isDebug) {
			console.warn("No child element found for focus");
		}
	} else if (isDebug) {
		console.warn("No parent element found for focus");
	}
}

export function getCurrentDomain() {
	let url = new URL(location.href);
	try {
		url = new URL(baseURL);
	} catch {
		// ignored - could break on prod because link is not valid
	}
	return url;
}

export function getLocalStorageReac(
	key: string,
	shouldParse = false,
	defaultVal: any = null,
	forceThrow = false,
): RemovableRef<any> | any {
	try {
		// eslint-disable-next-line no-storage/no-browser-storage
		const state = useStorage(key, defaultVal, localStorage);
		if (shouldParse && "value" in state) return JSON.parse(state.value);
		return state;
	} catch (err) {
		console.error("Error loading localStorage key", key);
		if (forceThrow) {
			throw err;
		} else {
			return defaultVal || null;
		}
	}
}

export function getSessionStorageReac(
	key: string,
	shouldParse = false,
	defaultVal: any = null,
	forceThrow = false,
): RemovableRef<any> | any {
	try {
		// eslint-disable-next-line no-storage/no-browser-storage
		const state = useStorage(key, defaultVal, sessionStorage);
		if (shouldParse && "value" in state) return JSON.parse(state.value);
		return state;
	} catch (err) {
		console.error("Error loading sessionStorage key", key);
		if (forceThrow) {
			throw err;
		} else {
			return defaultVal || null;
		}
	}
}
export function setLocalStorageReac(
	key: string,
	value: any,
): RemovableRef<any> {
	let tempValue = value;
	if (typeof value !== "string") {
		tempValue = JSON.stringify(value);
	}

	// GH: https://github.com/vueuse/vueuse/issues/2193
	// eslint-disable-next-line no-storage/no-browser-storage
	const state = useStorage(key, null, localStorage);
	state.value = tempValue;

	// eslint-disable-next-line no-storage/no-browser-storage
	if (localStorage[key] !== tempValue) {
		// eslint-disable-next-line no-storage/no-browser-storage
		localStorage[key] = tempValue;
		return ref(tempValue);
	}
	return state;
}

export function setSessionStorageReac(
	key: string,
	value: any,
): RemovableRef<any> {
	let tempValue = value;
	if (typeof value !== "string") {
		tempValue = JSON.stringify(value);
	}

	// GH: https://github.com/vueuse/vueuse/issues/2193
	// eslint-disable-next-line no-storage/no-browser-storage
	const state = useStorage(key, null, sessionStorage);
	state.value = tempValue;

	// eslint-disable-next-line no-storage/no-browser-storage
	if (sessionStorage[key] !== tempValue) {
		// eslint-disable-next-line no-storage/no-browser-storage
		sessionStorage[key] = tempValue;
		return ref(tempValue);
	}
	return state;
}

export function passwordFieldPolyfill(forceModern = false) {
	const isFirefox = navigator.userAgent.includes("Firefox");
	if (isFirefox && !forceModern) {
		return {
			type: "password",
			autocomplete: "off",
		};
	}
	return {
		type: "text",
		style: {
			"-webkit-text-security": "disc",
		},
	};
}

/**
 * Deprecated
 */
export function infoConsole(
	msg: string,
	type: "log" | "warn" | "error" = "log",
	...payload: any[]
) {
	console[type](`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
}

export const InfoConsole = {
	/**
	 * Console log with colors
	 */
	l(msg: string, ...payload: any[]) {
		console.log(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
	/**
	 * Console warn with colors
	 */
	w(msg: string, ...payload: any[]) {
		console.warn(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
	/**
	 * Console error with colors
	 */
	e(msg: string, ...payload: any[]) {
		console.error(`:: %c${msg}`, "color:yellow; font-weight:bold", ...payload);
	},
};

export function saveBlobFile(content: Blob, fileName: string) {
	const isBlob = content instanceof Blob;
	if (!isBlob) {
		console.error("Not a blob", content);
		return;
	}

	const a = document.createElement("a");
	const url = window.URL.createObjectURL(content);
	a.href = url;
	a.download = fileName;
	a.click();
	if (isBlob) {
		window.URL.revokeObjectURL(url);
	}
}

/**
 * Old lib (jszip), better to use fflate
 */
export async function generateZipJsZip(
	zipInstance: typeof JSZip,
	zipArchiveName: string,
): Promise<boolean> {
	const updateZipCallback = (data: JSZipMetadata) => {
		// console.log("Update zip", data);
	};
	try {
		const zipBlob = await zipInstance.generateAsync(
			{
				type: "blob",
				compression: "DEFLATE",
				compressionOptions: {
					level: 9,
				},
				streamFiles: true, // Testing for memory improvement: stuk.github.io/jszip/documentation/api_jszip/generate_async.html#streamfiles-option
			},
			updateZipCallback,
		);

		saveBlobFile(zipBlob, zipArchiveName);
		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

export function cookieTest(iFrameUri: string, callBack: any) {
	const messageHandler = (event: MessageEvent) => {
		// check for trusted origins here
		const data =
			typeof event.data === "string" ? JSON.parse(event.data) : event.data;
		callBack(data.result);
		window.removeEventListener("message", messageHandler);
		frame.remove();
	};

	window.addEventListener("message", messageHandler);
	const frame = document.createElement("iframe") as any;
	frame.src = iFrameUri;
	frame.sandbox = "allow-scripts allow-same-origin";
	frame.style = `display:none`;
	frame.addEventListener("load", (e: any) => {
		frame.contentWindow.postMessage(JSON.stringify({ test: "cookie" }), "*");
	});
	document.body.append(frame);
}
