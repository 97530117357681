<template>
	<div :style="elStyle" class="table-inner-wrap" @scroll="tableScrolled">
		<table class="table">
			<thead>
				<tr>
					<!-- :style="[column.width ? {'width':column.width}:{'width':'auto'}]" -->
					<!-- v-if="column.visibility == null || !column.visibility === false" -->
					<th
						v-for="(column, index) in getFilteredColumns"
						:key="column.id"
						:style="column.style"
						class="columnheader"
						:class="column.class"
						@click="selectColumn(index)"
					>
						<div class="label-wrapper">
							<span>{{ column.label }}</span>
							<i v-if="isColumnSortable(column)" class="fas fa-sort right"></i>
						</div>
						<i v-if="!column.label" :class="`${column.icon}`"></i>
					</th>
				</tr>
			</thead>
			<tbody v-show="getRows.length > 0" ref="tbody">
				<template v-for="(row, index) in getRows">
					<tr v-if="row.slot" :key="index">
						<td colspan="100%">
							<TableRow
								:key="row.id + 'r1'"
								class="slot"
								:columns="getColumns"
								:data="row"
								v-html="row.slot"
							></TableRow>
						</td>
					</tr>
					<TableRow
						v-else-if="row.visibility == null || !row.visibility === false"
						:key="index + 'r2'"
						:columns="getColumns"
						:data="row"
						:rowIndex="index"
						@toggleDropdown="toggleDropdown($event)"
						@saveChanges="$emit('saveChanges', $event)"
						@discardChanges="$emit('discardChanges', $event)"
						@deleteRow="$emit('deleteRow', $event)"
						@cellSelected="selectRow(row, $event, index)"
						@checkboxChanged="$emit('checkboxChanged', { $event, row, index })"
					></TableRow>
					<tr
						v-if="row.hasOwnProperty('dropdown')"
						:key="index + 'b'"
						class="dropdown-tr"
					>
						<td colspan="100%">
							<div :class="'dropdown t' + tableId + '_' + index">
								<div
									v-for="(value, key) in row.dropdown"
									:key="key"
									class="dropdown-section"
								>
									<span class="dropdown-key">{{ key }}:</span>
									<span class="dropdown-value">{{ value }}</span>
								</div>
							</div>
						</td>
					</tr>
				</template>
			</tbody>
			<tbody v-show="getRows.length == 0">
				<tr>
					<td class="no-data" colspan="100%">No data in table.</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import TableRow from "./TableRow.vue";
import Sortable from "sortablejs";
export default {
	components: {
		TableRow,
	},
	props: {
		data: {
			required: true,
		},
		elStyle: {
			type: Object,
			required: false,
		},
		tableId: {
			type: String,
			required: false,
		},
	},
	emits: ["infiniteScroll", "getColumnData", "getRowData", "sortable"],
	data() {
		return {
			requesting: false,
		};
	},
	computed: {
		getColumns() {
			return this.data.columns;
		},
		getFilteredColumns() {
			const ar = [];
			for (let i = 0; i < this.data.columns.length; i++) {
				const column = this.data.columns[i];
				if (column.visibility == null || !column.visibility === false) {
					ar.push(column);
				}
			}
			return ar;
		},
		getRows() {
			// this.data.rows[0].dropdown = JSON.parse('{"message":"Error reading file: static\/main2.jpg","tag":"Error reading file: static\/main2.jpg","log":"Error reading file: static\/main2.jpg"}');
			// let entries = Object.entries(this.data.rows[0].dropdown)
			// this.data.rows[0].dropdown = entries
			const res = this.data.rows;
			if (
				this.checkEditableColumns() &&
				!this.checkForEditRow() &&
				res.length > 0 &&
				this.data.editableLastRow &&
				this.data.editableLastRow === true
			) {
				const row = {
					values: JSON.parse(JSON.stringify(this.data.rows[0].values)),
					visibility: true,
					forEdit: true,
				};
				for (const variable in row.values) {
					if (row.values.hasOwnProperty(variable) && variable !== "marsId") {
						row.values[variable].value = null;
					} else if (variable === "marsId") {
						row.values[variable].value = Math.random().toString(36).slice(2, 7);
					}
				}
				res.push(row);
			}
			return res;
		},
		getRowDropdownSections(dropdown) {
			console.log(dropdown);
			return false;
		},
	},
	mounted() {
		this.setUpSortable();
	},
	methods: {
		isColumnSortable(col) {
			return col.hasOwnProperty("sortable") && col.sortable === true;
		},
		checkEditableColumns() {
			let editable = false;
			for (let i = 0; i < this.data.columns.length; i++) {
				if (
					this.data.columns[i].editable &&
					this.data.columns[i].editable === true
				) {
					editable = true;
				}
			}
			return editable;
		},
		checkForEditRow() {
			let has = false;
			for (let i = 0; i < this.data.rows.length; i++) {
				if (this.data.rows[i].forEdit && this.data.rows[i].forEdit === true) {
					has = true;
				}
			}
			return has;
		},
		tableScrolled(e) {
			if (this.requesting) {
				return;
			}
			if (
				e.target.firstElementChild.offsetHeight -
					(e.target.scrollTop + e.target.offsetHeight) <
				50
			) {
				this.requesting = true;
				this.$emit("infiniteScroll");
				setTimeout(() => {
					this.requesting = false;
				}, 3000);
			}
		},
		setUpSortable() {
			const el = this.$refs.tbody;
			if (this.data.sortable && this.data.sortable === true) {
				Sortable.create(el, {
					animation: 100,
					onUpdate: (evt) => {
						evt.preventDefault();
						const res = {
							oldIndex: evt.oldIndex,
							newIndex: evt.newIndex,
						};
						this.$emit("sortable", res);
					},
				});
			}
		},
		selectColumn(index) {
			const data = {};
			data.column = this.data.columns[index];
			this.$emit("getColumnData", data);
		},
		toggleDropdown(e) {
			const dropdown = document.querySelector(
				".t" + this.tableId + "_" + e.index,
			);
			if (dropdown.classList.contains("active")) {
				dropdown.classList.remove("active");
			} else {
				dropdown.classList.add("active");
			}
		},
		selectRow(row, res, index) {
			const data = {};
			data.column = res.val.name;
			data.row = {};
			console.warn(row);
			for (const variable in row.values) {
				if (row.values.hasOwnProperty(variable)) {
					data.row[variable] = row.values[variable].value;
				}
			}
			data.rowIndex = index;
			console.log(data);
			this.$emit("getRowData", data);
		},
	},
};
</script>
<style lang="scss" scoped>
.query-result .table {
	table-layout: fixed;
}

.no-data {
	padding-left: 10px !important;
}

.table-inner-wrap {
	overflow: auto;
	width: 100%;

	.table {
		width: inherit;
		border-spacing: 0;
		border-collapse: separate;

		th {
			text-align: left;
			position: sticky;
			top: 0;
			padding-bottom: 10px;
			padding-right: 10px;
			width: auto;
			z-index: 1;
			box-sizing: border-box;
			cursor: pointer;

			// background: $dark-gray;
		}

		tr th:first-child {
			padding-left: 10px;
		}

		tr {
			cursor: pointer;
		}

		tbody {
			tr:hover:not(.dropdown-tr) {
				background-color: #323741;

				td:first-child {
					border-bottom-left-radius: 8px;
					border-top-left-radius: 8px;
				}

				td:last-child {
					border-bottom-right-radius: 8px;
					border-top-right-radius: 8px;
				}
			}
		}

		td {
			padding: 1px;
			min-width: 100px;
			border-radius: 8px !important;
		}

		i {
			margin-left: 8px;
		}

		td span {
			max-height: 27px;
		}
	}
}

.dropdown-tr {
	td {
		padding: 0 !important;

		.dropdown {
			height: 0;
			overflow: hidden;
			transition: 0.3s;
			border: none;
			color: $dark-silver;
		}

		.dropdown.active {
			height: auto;

			// border-top: 1px solid #323741;
			// border-bottom: 1px solid #323741;
			background-color: #252932;
			border-radius: 5px;
			padding: 10px;

			.dropdown-section {
				display: flex;
				flex-direction: row;
				align-items: center;
				border-bottom: 1px solid #323741;
				padding-bottom: 5px;

				.dropdown-key {
					// font-size: 20px;
					font-weight: bold;
					padding-left: 5px;
				}

				.dropdown-value {
					font-size: 14px;
					padding-left: 15px;
					max-height: none;
				}
			}

			.dropdown-section:last-child {
				border-bottom: none;
			}
		}
	}
}

.columnheader {
	.label-wrapper {
		max-height: 25px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}

.query-result .columnheader {
	padding-right: 15px !important;
}
</style>
