// Don't import new routes here!!! Add dynamic importing below > build will crash otherwise

const routes = {
	namespace: "",
	routes: [
		{
			path: "",
			name: "MainPage",
			component: () => import("@/modules/main/components/MainPage.vue"),
			redirect: "projects",
			meta: {
				needsSid: true,
			},
			children: [
				{
					path: "projects",
					name: "ProjectList",
					component: () => import("@/modules/project-list/ProjectList.vue"),
					meta: {
						isForcedSidebarState: true,
					},
				},
				{
					path: "editor",
					name: "EditorPage",
					component: () => import("@/modules/editor-wrap/EditorPage.vue"),
					meta: {
						needsProject: true,
					},
				},
				{
					path: "dashboard",
					name: "DashboardPage",
					component: () => import("@/modules/dashboard/DashboardPage.vue"),
					meta: {
						needsProject: true,
					},
				},
				{
					path: "import",
					name: "ImportPageComp",
					props: true,
					component: () => import("@/modules/import-export/ImportPageWrap.vue"),
					children: [
						{
							path: "",
							name: "ImportPage",
							component: () =>
								import("@/modules/import-export/components/uploadFile.vue"),
						},
						{
							path: "export",
							name: "ExportPage",
							component: () =>
								import("@/modules/import-export/components/downloadFile.vue"),
						},
						{
							path: "new-export",
							name: "NewExportPage",
							component: () =>
								import(
									"@/modules/import-export/components/ExportFiles2023.vue"
								),
						},
						{
							path: "database",
							name: "DatabaseExportPage",
							component: () =>
								import("@/modules/import-export/components/downloadDB.vue"),
						},
						{
							path: "node",
							name: "NodeExportPage",
							component: () =>
								import("@/modules/import-export/components/ExportNodejs.vue"),
						},
					],
				},
				{
					path: "recovery",
					name: "RecoveryComp",
					component: () => import("@/modules/recovery/Recovery.vue"),
					meta: {
						needsProject: true,
					},
				},
				{
					path: "settings",
					name: "SettingsPage",
					redirect: "/settings/software",
					component: () => import("@/modules/settings-page/SettingsPage.vue"),
					props: true,
					meta: {
						needsProject: true,
					},
					children: [
						{
							path: "software",
							name: "SettingsSoftware",
							component: () =>
								import(
									"@/modules/settings-page/components/SettingsSoftware.vue"
								),
						},
						{
							path: "init",
							name: "SettingsInitScripts",
							component: () =>
								import(
									"@/modules/settings-page/components/SettingsInitScripts.vue"
								),
						},
						{
							path: "domains",
							name: "SettingsDomains",
							component: () =>
								import(
									"@/modules/settings-page/components/SettingsDomains.vue"
								),
							meta: {
								isSearchEnabled: false,
							},
						},
						{
							path: "users",
							name: "SettingsUsers",
							component: () =>
								import("@/modules/settings-page/components/SettingsUsers.vue"),
						},
						{
							path: "events",
							name: "SettingsEvents",
							component: () =>
								import("@/modules/settings-page/components/SettingsEvents.vue"),
							meta: {
								isSearchEnabled: false,
							},
						},
						{
							path: "transfer",
							name: "SettingsTransfer",
							component: () =>
								import(
									"@/modules/settings-page/components/SettingsTransfer.vue"
								),
						},
						{
							path: "filesystem",
							name: "SettingsFileSystem",
							component: () =>
								import(
									"@/modules/settings-page/components/SettingsFileSystem.vue"
								),
							meta: {
								isSearchEnabled: false,
							},
						},
						{
							path: "project",
							name: "SettingsProjectsSettings",
							component: () =>
								import(
									"@/modules/settings-page/components/SettingsProjectsSettings.vue"
								),
							meta: {
								isSearchEnabled: false,
							},
						},
						{
							path: "powerups",
							name: "SettingsProjectPowerups",
							component: () =>
								import(
									"@/modules/settings-page/components/SettingsPowerups/SettingsProjectPowerups.vue"
								),
						},
					],
				},
				{
					path: "errors",
					name: "ErrorsComp",
					component: () => import("@/modules/errors-page/ErrorsComp.vue"),
					meta: {
						needsProject: true,
					},
				},
				{
					path: "database",
					name: "DatabaseComp",
					component: () => import("@/modules/database-page/DatabaseComp.vue"),
					meta: {
						needsProject: true,
					},
				},
				{
					path: "objectStorage",
					name: "ObjectStoragePage",
					component: () =>
						import("@/modules/object-storage/ObjectStoragePage.vue"),
					meta: {
						needsProject: true,
					},
				},
				{
					path: "task",
					name: "TaskView",
					component: () => import("@/modules/tickets/TaskView.vue"),
					props: true,
					children: [
						{
							path: "/task/:taskId",
							component: () =>
								import("@/modules/tickets/components/TaskInfo.vue"),
							redirect: "/task/:taskId/info",
							children: [
								{
									path: "info",
									name: "TaskBasicInfo",
									component: () =>
										import("@/modules/tickets/components/TaskBasicInfo.vue"),
								},
								{
									path: "chat",
									name: "TaskPageChat",
									component: () =>
										import(
											"@/modules/tickets/components/chat/TaskPageChat.vue"
										),
								},
							],
						},
					],
				},
				{
					path: "payments",
					name: "PaymentsPage",
					redirect: { name: "PaymentList" },
					component: () => import("@/modules/payment/PaymentsPage.vue"),
					meta: {
						needsProject: true,
					},
					children: [
						{
							path: "",
							name: "PaymentList",
							component: () =>
								import("@/modules/payment/components/PaymentList.vue"),
							children: [
								{
									path: "individual",
									name: "SubsSolo",
									component: () =>
										import("@/modules/payment/components/PackagesList.vue"),
								},
								{
									path: "team",
									name: "SubsTeam",
									component: () =>
										import("@/modules/payment/components/PackagesList.vue"),
								},
							],
						},
					],
				},
				{
					path: "profile",
					// name: 'ProfileComp',
					component: () => import("@/modules/profile/ProfileComp.vue"),
					children: [
						{
							path: "info",
							name: "ProfileInformation",
							component: () =>
								import("@/modules/profile/components/ProfileInformation.vue"),
						},
						{
							path: "tutorials",
							name: "TutorialManage",
							component: () =>
								import("@/modules/profile/components/TutorialManage.vue"),
						},
						{
							path: "editor",
							name: "EditorSettings",
							component: () =>
								import(
									"@/modules/profile/components/editor/EditorSettings.vue"
								),
						},
						{
							path: "two-factor",
							name: "TwoFactorQR",
							component: () =>
								import("@/modules/auth/components/TwoFactorQR.vue"),
						},
						{
							path: "notifications",
							name: "NotificationSettings",
							component: () =>
								import(
									"@/modules/profile/components/notification/NotificationSettings.vue"
								),
						},
					],
				},
				{
					path: "superadmin",
					name: "SuperadminPage",
					component: () =>
						import("@/modules/superadmin-panel/SuperadminPage.vue"),
					redirect: "/superadmin/sites",
					props: true,
					children: [
						{
							path: "sites",
							name: "SuperadminSites",
							component: () =>
								import(
									"@/modules/superadmin-panel/components/SuperadminSites.vue"
								),
						},
						{
							path: "users",
							name: "SuperadminUsers",
							component: () =>
								import(
									"@/modules/superadmin-panel/components/SuperadminUsers.vue"
								),
						},
					],
				},
				{
					path: "templates",
					name: "TemplatesPage",
					component: () => import("@/modules/templates/TemplatesPage.vue"),
					props: true,
					meta: {
						needsProject: true,
					},
				},
				{
					path: "html",
					name: "HtmlBuilderPage",
					component: () => import("@/modules/html/HtmlPage.vue"),
					props: true,
					meta: {
						needsProject: true,
					},
				},
			],
		},
		{
			// Hardcoded [for compatibility]
			path: "/reset/:email/:token",
			redirect: "/auth/reset/:email/:token",
		},
	],
};

export default routes;
