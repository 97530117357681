<template>
	<div class="modal-wrapper">
		<!-- <div class="modal-title">
      Add init script
    </div> -->
		<div class="section">
			<span class="title">Init script full path:</span>
			<input
				v-model="script"
				type="text"
				@keyup.enter="addScript"
				@keyup.esc="closeModernModalCompat()"
			/>
		</div>
		<div class="section actions">
			<button class="btn close inv" @click="closeModernModalCompat()">
				close
			</button>
			<button class="btn create" @click="addScript()">add</button>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
	name: "SettingsAddConfig",
	data() {
		return {
			script: "",
			scripts: "",
		};
	},
	mounted() {
		this.getSiteInitScripts().then((response) => {
			this.scripts = response.data.payload[0].cfg_value;
		});
	},
	methods: {
		...mapActions({
			addScriptAction: "moduleSettings/addScript",
			getSiteInitScripts: "moduleSettings/getSiteInitScripts",
		}),
		addScript() {
			if (this.script === "") {
				console.log("missing path");
				this.closeModernModalCompat();
			} else {
				let scrStr = this.scripts;
				scrStr += this.scripts === "" ? "" : ",";
				scrStr += this.script;
				const params = {
					scripts: scrStr,
				};
				this.addScriptAction(params)
					.then((response) => {
						this.openToastSuccessMsg("Init script added successfully");
						this.closeModernModalCompat();
					})
					.catch((e) => {
						this.openToastErrorMsg("Error creating init script");
					});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/adminModals";
.modal-wrapper {
	@include admin-modals-styles;
}
</style>
