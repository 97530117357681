import AbstractCommand from "../AbstractCommand";

/*
    parameters: {
        added: [
            {
                name: "String",
                "type": {
                    "name": "INTEGER",
                    "length": 10
                },
                order: 1,
                after: String (column name),
                "options": {
                    "autoIncrement": false,
                    "binary": false,
                    "generated": false,
                    "notNull": true,
                    "primaryKey": false,
                    "unique": false,
                    "unsigned": false,
                    "zerofill": false
                },
                "default": "String/Integer",
                "comment": "String",
                "collation": {
                    "name": "String",
                    "characterSet": "String"
                }
            }
        ],
        deleted: [
            "String"
        ],
        changed: [
            {
                name: "String",
                "type": {
                    "name": "INTEGER",
                    "length": 10
                }
                "options": {
                    "autoIncrement": false,
                    "binary": false,
                    "generated": false,
                    "notNull": true,
                    "primaryKey": false,
                    "unique": false,
                    "unsigned": false,
                    "zerofill": false
                },
                "default": "Value",
                "comment": "String",
                "collation": {
                    "name": "String",
                    "characterSet": "String"
                }
            }
        ],
        reordered: [
            {
                name: "String",
                "type": {
                    "name": "INTEGER",
                    "length": 10
                },
                order: "Integer",
                after: "String",
                "options": {
                    "autoIncrement": false,
                    "binary": false,
                    "generated": false,
                    "notNull": true,
                    "primaryKey": false,
                    "unique": false,
                    "unsigned": false,
                    "zerofill": false
                },
                "default": "Value",
                "comment": "String",
                "collation": {
                    "name": "String",
                    "characterSet": "String"
                }
            }
        ],
        oldTableName: String,
        newTableName: String,
        tableComment: String
    }
 */

class AlterTableCommand extends AbstractCommand {
	constructor(parameters) {
		super("AlterTable");
		this.added = parameters.added;
		this.deleted = parameters.deleted;
		this.changed = parameters.changed;
		this.reordered = parameters.reordered;
		this.newTableName = parameters.newTableName;
		this.oldTableName = parameters.oldTableName;
		this.tableComment = parameters.tableComment;
		this.collation = parameters.tableCollation;
	}

	execute() {
		let ddl = "";
		let ddlParts = [];
		if (this.added) {
			for (const column of this.added) {
				ddlParts.push(this.addColumnDDL(column));
			}
		}
		if (this.deleted) {
			for (const column of this.deleted) {
				ddlParts.push(this.dropColumnDDL(column));
			}
		}
		if (this.changed) {
			for (const column of this.changed) {
				ddlParts.push(this.changeColumnDDL(column));
			}
		}
		if (this.reordered) {
			for (const column of this.reordered) {
				ddlParts.push(this.reorderedColumnDDL(column));
			}
		}
		if (this.tableComment) ddlParts.push(this.getTableComment());

		if (this.collation) {
			ddlParts.push(this.getTableCollation());
		}

		if (ddlParts.length > 0) {
			ddl += `ALTER TABLE \`${this.oldTableName}\`\n`;
			ddl += this.concat(", \n", ddlParts);
		}
		return ddl;
	}

	getTableComment() {
		return `COMMENT = '${this.tableComment}'`;
	}

	getTableCollation() {
		let charSet = this.collation.split("_")[0];
		return `CONVERT TO CHARACTER SET '${charSet}' COLLATE '${this.collation}'`;
	}

	addColumnDDL(column) {
		return (
			"ADD COLUMN `" +
			column.name +
			"` " +
			this.getAllColumnOptions(column) +
			" " +
			this.getColumnPosition(column)
		);
	}

	dropColumnDDL(column) {
		return "DROP COLUMN `" + column + "`";
	}

	changeColumnDDL(column) {
		let name = column.name;
		if (column.oldEntity && column.oldEntity.name !== name)
			name = column.oldEntity.name;
		return `CHANGE COLUMN \`${name}\` \`${
			column.name
		}\` ${this.getAllColumnOptions(column)}`;
	}

	reorderedColumnDDL(column) {
		return `CHANGE COLUMN \`${column.name}\` \`${
			column.name
		}\` ${this.getAllColumnOptions(column)} ${this.getColumnPosition(column)}`;
	}

	getColumnType(column) {
		let type = column.type;
		console.log(type);
		let str = "";
		if (type.length) {
			str = `${type.name}(${type.length}`;
			if (type.decimal) str += `,${type.decimal}`;
			str += ") ";
		} else {
			str = type.name;
		}
		return str;
	}

	getColumnPosition(column) {
		if (column.after) return `AFTER \`${column.after}\``;
		return "FIRST";
	}

	getColumnOptions(column) {
		let query = [];
		let options = {
			unsigned: "UNSIGNED",
			notNull: "NOT NULL",
			unique: "UNIQUE",
			autoIncrement: "AUTO_INCREMENT",
			binary: "BINARY",
			zerofill: "ZEROFILL",
		};
		for (let option in options) {
			if (column.options[option]) query.push(options[option]);
		}
		return this.concat(" ", query) || "";
	}

	getColumnComment(column) {
		return column.comment
			? `COMMENT '${column.comment.replace(/'/g, "\\'")}'`
			: "";
	}

	getColumnDefault(column) {
		if (column.default) {
			return column.options.generated
				? `GENERATED ALWAYS AS (${column.default})`
				: `DEFAULT ${column.default}`;
		}
		return "";
	}

	getColumnCharset(column) {
		if (column.collation && column.collation.name) {
			let collation = column.collation.name.split("_"); // if the format is as in the following example: utf8_slovenian_ci
			return `\nCHARACTER SET '${collation[0]}' COLLATE '${column.collation.name}'`;
		}
		return "";
	}

	getAllColumnOptions(column) {
		return this.concat(
			" ",
			this.pickNonFalsy(
				this.getColumnType(column),
				this.getColumnOptions(column),
				this.getColumnCharset(column),
				this.getColumnDefault(column),
				this.getColumnComment(column),
			),
		);
	}

	pickNonFalsy() {
		let arr = [];
		for (let i in arguments) {
			if (arguments[i] && arguments[i].length !== 0) arr.push(arguments[i]);
		}
		return arr;
	}

	concat(separator) {
		let res = "";
		for (let i = 1; i < arguments.length; i++) {
			if (Array.isArray(arguments[i])) {
				for (let j in arguments[i]) {
					res += separator + arguments[i][j];
				}
			} else {
				res += separator + arguments[i];
			}
		}
		return res.slice(separator.length);
	}
}

export default AlterTableCommand;
