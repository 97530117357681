<template lang="pug">
.popup(:style="popupCompStyle")
	.outer
		header(v-if="popupHeader", :class="{ 'no-header': !popupHeader }")
			transition(name="slide-left")
				.title(v-if="isHeaderDisplayed")
					.text {{ popupHeader }}
		component(
			:is="dynamicPopup",
			:popupData="popupData",
			:popupEvents="popupEvents",
			@close-popup="$emit('close-popup')"
		)
</template>

<script>
import PopupAlert from "./popup-types/PopupAlert.vue";
import PopupConfirm from "./popup-types/PopupConfirm.vue";
import PopupPrompt from "./popup-types/PopupPrompt.vue";
export default {
	components: {
		PopupAlert,
		PopupConfirm,
		PopupPrompt,
	},
	props: {
		popupData: {
			type: Object,
			required: true,
		},
		popupEvents: {
			type: Object,
			required: true,
		},
	},
	emits: ["close-popup"],
	data() {
		return {
			isHeaderDisplayed: false,
		};
	},
	computed: {
		dynamicPopup() {
			switch (this.popupData.type) {
				case "alert": {
					return PopupAlert;
				}
				case "confirm": {
					return PopupConfirm;
				}
				case "prompt": {
					return PopupPrompt;
				}
				default: {
					return PopupAlert;
				}
			}
		},
		popupHeader() {
			return this.popupData?.header;
		},
		popupCompStyle() {
			if (this.popupHeader) {
				return {
					minHeight: "150px",
				};
			}
			return {};
		},
	},
	mounted() {
		this.displayHeaderTrans();
	},
	beforeUnmount() {
		this.isHeaderDisplayed = false;
	},
	methods: {
		displayHeaderTrans() {
			setTimeout(() => {
				this.isHeaderDisplayed = true;
			}, 100);
		},
	},
};
</script>

<style lang="scss" scoped>
.popup {
	@include modal-comp;

	// position: relative;
	width: 300px; // Fallback
	width: max-content;
	margin: auto;
	min-width: 350px;

	.inner {
		min-height: 120px;
		width: 100%;
		padding: 20px 40px;
		overflow: auto;
		flex: 1;
		justify-content: space-between;
	}
}
</style>

<style lang="scss">
.popup {
	.content {
		max-height: 70vh;
		overflow: hidden;
		overflow-y: auto;

		.text {
			margin-bottom: 10px;
		}

		input {
			width: 100%;
		}
	}

	.error-wrap {
		width: calc(100% - 20px);
		margin: 10px;
		min-height: 25px;

		.error {
			color: $error-color;
			max-width: 60vw;
		}
	}

	.button-group {
		display: flex;
		justify-content: flex-end;
		gap: 10px;
		margin: 20px 0 0;

		button {
			padding: 10px 15px;
		}
	}
}
</style>
