import api from "@/api";
import { infoConsole } from "@/assets/js/helpers";
import { i18n } from "@/i18n.ts";
import router from "@/router";
import userConfigKeys from "@/script/userConfigKeys.js";

export default {
	namespaced: true,
	state: {
		projectMetaData: {},
		projectData: {},
		siteSelected: {
			// SEP 21 - This is now site data
			dbs_defdomain: null,
			dbs_id: null,
			dcn_name: null,
			dcn_state: null,
			on_site: null,
			sit_alias: null,
			sit_id: null,
			sit_name: null,
			srv_id: null,
			srv_name: null,
			urls: null,
			usr_email: null,
			usr_id: null,
			usr_name: null,
		},
		recentProjects: [], // From old to new (in comp reversed)
		projectSiteOptionsData: {},
	},
	getters: {
		projectGenDomainLink: (state) => {
			const ss = state.siteSelected;

			const url = "";
			const getDefaultUrl = () => {
				if (ss.sit_name && ss.srv_name) {
					return `http://${ss.sit_name}.${ss.srv_name}.mars-hosting.com`;
				}
				return "";
			};

			const getUrl = () => {
				if (ss.urls) {
					const replaceUrlSpace = ss.urls.replace(/\s+/gu, "");
					const splitted = replaceUrlSpace.split(",");
					if (splitted.length === replaceUrlSpace.length + 1) {
						// Invalid backend response
						return getDefaultUrl();
					}

					if (splitted.length > 1) {
						return `http://${splitted[0]}`;
					} else if (splitted.length === 1) {
						return `http://${ss.urls}`;
					} else {
						console.warn("Invalid split", ss.urls);
					}
				}
				return getDefaultUrl();
			};

			try {
				const urlStr = getUrl();
				const url = new URL(urlStr);
				return url.origin;
			} catch (err) {
				console.warn(err.message, url);
				return null;
			}
		},
		projectGenDomainMarsLink: (state) => {
			// This is used when domain used is broken [Preview Image]
			const pr = state.siteSelected;
			if (pr.sit_name && pr.srv_name) {
				return `http://${pr.sit_name}.${pr.srv_name}.mars-hosting.com`;
			}
			return "";
		},
		getValidRecentProjects: (state) =>
			state.recentProjects.filter((rp) => rp?.pro_id),
		getProjectInfo: (state) => state.projectData,
		getProjectSites: (state, getters) => getters.getProjectInfo.sites || [],
		getSiteData: (state) => state.siteSelected,
		getProjectName: (state) =>
			state.projectData.pro_name || state.siteSelected.sit_alias,
		getProjectSiteOptionsData: (state) => state.projectSiteOptionsData,
	},
	mutations: {
		STORE_PROJECT_META_DATA(state, payload) {
			state.projectMetaData = payload;
		},
		MERGE_SITE_INFO_STORE(state, payload) {
			state.siteSelected = { ...state.siteSelected, ...payload };
		},
		MERGE_PROJECT_DATA(state, payload) {
			state.projectData = { ...state.projectData, ...payload };
		},
		SET_PROJECT_DATA(state, payload) {
			state.projectData = payload;
		},
		CLEAR_ONLY_PROJECT_INFO(state, payload) {
			state.projectSiteOptionsData = {};
			state.siteSelected = {};
			state.projectMetaData = {};
			state.projectData = {};
		},
		REMOVE_PROJECT_RECENT(state, index) {
			state.recentProjects.splice(index, 1);
		},
		SET_PROJECT_RECENT(state, payload = []) {
			state.recentProjects = payload;
		},
		ADD_PROJECT_TO_RECENT(state, payloadArr) {
			const MAX_LIMIT = 5;
			const latestProjs = payloadArr.slice(-MAX_LIMIT);
			if (state.recentProjects.length === 0) {
				state.recentProjects = payloadArr;
				return;
			}

			for (const proj of latestProjs) {
				if (state.recentProjects.length === MAX_LIMIT) {
					state.recentProjects.splice(0, 1);
				}
				state.recentProjects.push(proj);
			}
		},
		STORE_PROJECT_SITE_OPTIONS_DATA(state, data) {
			state.projectSiteOptionsData = data;
		},
	},
	actions: {
		getSitesList: (s, data) => api.getSitesList(data),
		getProjectData: (s, data) => api.getProjectData(data),
		updateProjectData: (s, data) => api.updateProjectData(data),
		deleteProject: (s, data) => api.deleteProject(data),
		getProjects: (s, data) => api.getProjects(data),
		postProjects: (s, data) => api.postProjects(data),
		reqProjectSiteOptions: async ({ commit }, data) => {
			try {
				const res = await api.reqProjectSiteOptions(data);
				commit("STORE_PROJECT_SITE_OPTIONS_DATA", res.data.data || {});
			} catch (err) {
				console.log("Error site options", err.message);
			}
		},
		postSites: (s, data) => api.postSites(data),

		renameAlias: (s, payload) => api.renameAlias(payload),
		getDatabaseData: (s, payload) => api.getDatabaseData(payload),
		getSiteOptions: (s, payload) => api.getSiteOptions(payload),
		autoEnterSite: async ({ dispatch }, payload) => {
			try {
				const response = await api.reqSiteData(payload.site);
				const siteData = response.data?.payload;
				const db = siteData?.sit_name;
				if (db) {
					const resDb = await dispatch("setSelectDb", db);
					if (resDb.data?.result === "OK") {
						await dispatch("siteUpdateData", siteData);
						dispatch("setDomainAutoSiteOpen", siteData, { root: true });
						return true;
					}
				}
				console.warn("Error auto entering site 1");
				return false;
			} catch (err) {
				console.error("Error auto entering site 2");
				throw err;
			}
		},
		getSiteMetainfo: async ({ commit }, data) => {
			try {
				const response = await api.getSiteMetainfo(data);
				commit("STORE_PROJECT_META_DATA", response?.data?.data || {});
			} catch (err) {
				console.log("Error", err.message);
			}
		},
		getProjectUsers: (s, data) => api.getProjectUsers(data),
		async fetchRecentProjects({ state, dispatch, commit }, data) {
			const recentProjectConfigKey = userConfigKeys.project.recentList;
			const resArr =
				(await dispatch("getUserConfig", recentProjectConfigKey, {
					root: true,
				})) || [];
			const recentProjectIds = resArr.map((rp) => rp.pro_id);
			const procRecentProjs = [];
			try {
				for (const rpId of recentProjectIds) {
					const projectData = await api.getProjectData(rpId);
					// const siteData = await api.reqSiteData(projectData.sites[0]);

					procRecentProjs.push(projectData.data.data);
				}
			} catch (err) {
				console.log("Error", err.message);
			}

			commit("SET_PROJECT_RECENT", procRecentProjs);
		},
		removeProjectFromRecent({ state, getters, commit }, payload) {
			const { id, isCheckLast = false } = payload;
			// Worked before with sit_id ; now with pro_id
			const sameProjectIndex = getters.getValidRecentProjects.findIndex(
				(project) => project.pro_id === id,
			);

			// If last project same as new
			if (
				isCheckLast &&
				sameProjectIndex !== -1 &&
				sameProjectIndex === getters.getValidRecentProjects.length - 1
			) {
				return false;
			}

			if (sameProjectIndex !== -1) {
				commit("REMOVE_PROJECT_RECENT", sameProjectIndex);
			}
			return true;
		},
		saveRecentProjectDb({ state, getters, dispatch }, payload) {
			// Sep 21 - Filter out sites from projects; Previously used sites;

			const recentProjectConfigKey = userConfigKeys.project.recentList;
			const param = [
				{
					key: recentProjectConfigKey,
					value: JSON.stringify(
						getters.getValidRecentProjects.filter((rp) => rp.pro_id),
					),
				},
			];
			dispatch("setUserConfig", param, { root: true });
		},
		async manageProjectDbConfig({ dispatch, commit }, projData = {}) {
			if (projData.pro_id) {
				// Remove recent before adding new
				const remParams = {
					id: projData.pro_id,
					isCheckLast: true,
				};
				const isOk = await dispatch("removeProjectFromRecent", remParams);
				if (!isOk) {
					// Return if same proj as currently last one
					return;
				}

				commit("ADD_PROJECT_TO_RECENT", [projData]);
				await dispatch("saveRecentProjectDb");
			} else {
				console.warn("No valid recent proj to add");
			}
		},
		async setSelectDb({ dispatch }, db) {
			try {
				// Awaiting because of the response
				const postSel = await api.postSelectDB({ db });
				infoConsole(`🚪 Selecting DB: ${db}`);

				// Invokes API to get project metadata when entered project
				await dispatch("getSiteMetainfo");
				return postSel;
			} catch {
				const params = {
					data: {
						type: "alert",
						text: i18n.global.t("project.cantLoadProject"),
					},
				};
				dispatch("modulePopup/openPopupStorage", params, { root: true });
				await dispatch("moduleProject/clearOnlyProjectData", null, {
					root: true,
				});
			}
			return null;
		},
		changeEnvironment({ commit }, payload) {
			// This is not needed, but could be useful in future
			const projectId = payload.project.pro_id;
			sessionStorage.lastProjectID = projectId;

			// Set only sit id in session storage before refreshing
			sessionStorage.lastProjectSiteID = payload.item.sit_id;

			location.reload(true);
		},
		siteUpdateData: ({ commit }, data) => commit("MERGE_SITE_INFO_STORE", data),
		clearProjectData: async ({ commit, dispatch }, data) => {
			await dispatch("clearOnlyProjectData", data);
			commit("SET_PROJECT_RECENT", []);
		},
		clearOnlyProjectData: ({ commit }, data) =>
			commit("CLEAR_ONLY_PROJECT_INFO", data),
		setProjectData: ({ commit }, payload) =>
			commit("SET_PROJECT_DATA", payload),
		mergeProjectData: ({ commit }, payload) =>
			commit("MERGE_PROJECT_DATA", payload),
		async loadProjectOnly({ dispatch }, siteData) {
			const selectDBandEnter = async () => {
				const db = siteData.sit_name;
				try {
					const res = await dispatch("setSelectDb", db);
					if (res.data?.result === "OK") {
						// Project select
						dispatch("siteUpdateData", siteData);
						dispatch("setDomainAutoSiteOpen", siteData, { root: true });
						return siteData.sit_id;
					}
				} catch (err) {
					console.warn("🐜 Error logging in to the project", err.message);
					// fetchProjectList();
					return null;
				}
				return null;
			};

			await dispatch("moduleEditor/resetOpenFiles", {}, { root: true });
			return selectDBandEnter();
		},
		async openCleanStateProject(store, payload = {}) {
			if (payload.shouldCleanEnv !== false) {
				await store.dispatch(
					"moduleFileTree/clearTreeData",
					{},
					{ root: true },
				);
				await store.dispatch(
					"moduleEditor/clearEditorData",
					{},
					{ root: true },
				);
			}

			await router.push({
				name: payload.routeName || "DashboardPage",
				params: payload.routeParams,
				query: payload.routeQuery,
			});
		},
	},
};
