function generateNewTab(state) {
	const newTabId = state.nextId++;
	let newTabPath = "";
	newTabPath = "Query " + String(++state.queryPagesCounter);
	const res = {
		id: newTabId,
		path: newTabPath,
		type: "query",
	};
	return res;
}
function findNextActivTab(state) {
	for (let i = 0; i < state.tabs.length; i++) {
		if (state.tabs[i].id === state.activeTabId) {
			if (state.tabs[i + 1]) {
				return state.tabs[i + 1].id;
			} else if (state.tabs[i - 1]) {
				return state.tabs[i - 1].id;
			} else {
				return 0;
			}
		}
	}
}
const types = [
	{ type: "INT", label: "INT", length: false, default: "" },
	{ type: "VARCHAR", label: "VARCHAR", length: true, default: "" },
	{ type: "BLOB", label: "BLOB", length: false, default: "" },
	{ type: "BINARY", label: "BINARY", length: true, default: "" },
	{ type: "LONGBLOB", label: "LONGBLOB", length: false, default: "" },
	{ type: "MEDIUMBLOB", label: "MEDIUMBLOB", length: false, default: "" },
	{ type: "TINYBLOB", label: "TINYBLOB", length: false, default: "" },
	{ type: "VARBINARY", label: "VARBINARY", length: true, default: "" },
	{ type: "DATE", label: "DATE", length: false, default: "" },
	{ type: "DATETIME", label: "DATETIME", length: true, default: "" },
	{ type: "TIME", label: "TIME", length: true, default: "" },
	{ type: "TIMESTAMP", label: "TIMESTAMP", length: true, default: "" },
	{ type: "GEOMETRY", label: "GEOMETRY", length: false, default: "" },
	{
		type: "GEOMETRYCOLLECTION",
		label: "GEOMETRYCOLLECTION",
		length: false,
		default: "",
	},
	{ type: "LINESTRING", label: "LINESTRING", length: false, default: "" },
	{
		type: "MULTILINESTRING",
		label: "MULTILINESTRING",
		length: false,
		default: "",
	},
	{ type: "MULTIPOINT", label: "MULTIPOINT", length: false, default: "" },
	{ type: "MULTIPOLYGON", label: "MULTIPOLYGON", length: false, default: "" },
	{ type: "POINT", label: "POINT", length: false, default: "" },
	{ type: "POLYGON", label: "POLYGON", length: false, default: "" },
	{ type: "BIGINT", label: "BIGINT", length: true, default: "" },
	{ type: "DECIMAL", label: "DECIMAL", length: false, default: "" },
	{ type: "DOUBLE", label: "DOUBLE", length: false, default: "" },
	{ type: "FLOAT", label: "FLOAT", length: false, default: "" },
	{ type: "MEDIUMINT", label: "MEDIUMINT", length: true, default: "" },
	{ type: "REAL", label: "REAL", length: false, default: "" },
	{ type: "SMALLINT", label: "SMALLINT", length: true, default: "" },
	{ type: "TINYINT", label: "TINYINT", length: true, default: "" },
	{ type: "CHAR", label: "CHAR", length: true, default: "" },
	{ type: "JSON", label: "JSON", length: false, default: "" },
	{ type: "NVARCHAR", label: "NVARCHAR", length: true, default: "" },
	{ type: "LONGTEXT", label: "LONGTEXT", length: false, default: "" },
	{ type: "MEDIUMTEXT", label: "MEDIUMTEXT", length: false, default: "" },
	{ type: "TEXT", label: "TEXT", length: true, default: "" },
	{ type: "TINYTEXT", label: "TINYTEXT", length: false, default: "" },
	{ type: "BIT", label: "BIT", length: true, default: "" },
	{ type: "BOOLEAN", label: "BOOLEAN", length: false, default: "" },
	{ type: "ENUM", label: "ENUM", length: true, default: "" },
	{ type: "SET", label: "SET", length: true, default: "" },
];
export default {
	generateNewTab,
	findNextActivTab,
	types,
};
