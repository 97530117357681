<template lang="pug">
.test-page(:class="{ dark: isDarkEnv }")
	.header
		h4 Hello to hidden test page
		button(@click="isDarkEnv = !isDarkEnv") Toggle dark / light
	component(:is="componentName")
</template>

<script>
// This is your sandbox to add / change / test / remove components
// Be sure to clean out your data after you're done
//  namespace: 'debug', route: '/test-hd' , comp: 'TestPage'
import { mapActions } from "vuex";
export default {
	name: "TestPage",
	components: {},
	data() {
		return {
			componentName: "",
			isDarkEnv: true,
			intervalSes: null,
		};
	},
	mounted() {
		this.simpleExpandSession();
	},
	methods: {
		...mapActions(["moduleAuth/expandSession"]),
		simpleExpandSession() {
			const minutesToCheck = 60_000 * 15; // 15 min
			clearInterval(this.intervalSes);
			this.expandSession();
			this.intervalSes = setInterval(() => {
				this.expandSession();
			}, minutesToCheck);
		},
	},
};
</script>

<style lang="scss" scoped>
.test-page {
	background: #d5d5d5;
	width: 100%;
	height: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;

	> .header {
		display: flex;
		align-items: center;

		h4 {
			margin: 0 10px 0 0;
		}
	}

	&.dark {
		background: #181818;
		color: white;
	}
}
</style>
