class AbstractCommander {
	constructor() {
		this.commandsQueue = [];
	}
	/*
    Adding command to execution queue.
     */
	// public
	addCommand(command) {
		this.commandsQueue.push(command);
	}

	/*
    Method should execute all commands and concat result of each command.
    Return the new formed string.
     */
	// public
	generateDDL() {
		throw "Not supported operation";
	}
}

export default AbstractCommander;
