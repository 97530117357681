<template lang="pug">
.pass-strength
	.title {{ getTitle }}
	.progress
		.line(:class="'s' + textStrengthCat", :style="{ width: `${getProgress}%` }")
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			required: true,
			default: "",
		},
	},
	emits: ["changed"],
	computed: {
		textStrengthCat() {
			const step = 7;
			const len = Math.floor(this.text.length / step);
			return len > step * 2 ? 2 : len;
		},
		getTitle() {
			const translStr = ["Weak password", "Medium password", "Strong password"];
			return translStr[this.textStrengthCat];
		},
		getProgress() {
			return Math.ceil((this.textStrengthCat + 1) * 33);
		},
	},
	watch: {
		textStrengthCat(val) {
			this.$emit("changed", this.textStrengthCat);
		},
	},
};
</script>

<style lang="scss" scoped>
.pass-strength {
	display: flex;
	flex-direction: column;
	gap: 10px;

	.progress {
		width: 100%;
		background: #58585a;
		border-radius: 7px;

		.line {
			height: 3px;
			transition: all 0.3s ease;
			border-radius: 7px;

			&.s0 {
				background: $error-color;
			}

			&.s1 {
				background: $yellow;
			}

			&.s2 {
				background: $green;
			}
		}
	}
}
</style>
