<template lang="pug">
.login-form
	h4
		span {{ $t("auth.enterNewPasswordFor") }}
		span.bold {{ user.email }}
	label
		span.lbl Password
		.input-field
			input#reset_password.form-control.flat.flat-auth(
				v-model.trim="user.password",
				autofocus,
				type="password",
				required,
				name="reset_password",
				placeholder="Password"
			)
	label
		span.lbl Confirm password
		.input-field
			input#reset_password_2.form-control.flat.flat-auth(
				v-model.trim="user.password2",
				type="password",
				required,
				name="reset_password",
				placeholder="Repeat password",
				@keydown.enter="resetPassword"
			)
	//-  <div id="pass-f">
		  You'll receive an email with instructions to reset your password.
		  <br />Please check your spam folder.
		</div>
	.form-group
		button.btn-acc-alt(@click="submitPass") {{ $t("button.submit") }}
	router-link(:to="{ name: 'LoginComp' }")
		span Back to login
</template>

<script>
import { mapActions } from "vuex";
export default {
	data() {
		return {
			user: {
				email: "",
				password: "",
				password2: "",
				token: "",
			},
		};
	},
	mounted() {
		this.$el.querySelector("input")?.focus();
		this.parseMergeLinkData();
	},
	methods: {
		...mapActions({
			resetPass: "moduleAuth/resetPass",
		}),
		parseMergeLinkData() {
			this.user = { ...this.user, ...this.$route.params };
		},
		async submitPass() {
			try {
				const res = await this.resetPass(this.user);
				if (res.data?.message) {
					this.openToastWarningMsg(res.data.message);
					// this.$router.push({
					//   name: 'LoginComp'
					// })
				}
			} catch (err) {
				console.warn(err.message);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.login-form {
	@include auth-form;

	h4 {
		text-align: center;
		font-weight: 400;
		margin-bottom: 0;

		.bold {
			font-weight: 500;
		}
	}

	.form-group {
		margin: 40px 0 20px;
		text-align: center;
	}

	button {
		height: 46px;
		width: 145px;
	}

	a {
		color: $green;
		text-align: center;
	}
}
</style>
