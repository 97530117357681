import AbstractCommand from "../AbstractCommand";

class RenameTableCommand extends AbstractCommand {
	/*
        parameters: {
            oldTableName: String,
            newTableName: String
        }
     */

	constructor(oldTable, newTable) {
		super("RenameTable");
		this.oldTableName = oldTable.name;
		this.newTableName = newTable.name;
	}

	execute() {
		return `ALTER TABLE \`${this.oldTableName}\` RENAME TO \`${this.newTableName}\``;
	}
}

export default RenameTableCommand;
