import Command from "../AbstractCommand.js";

/* INTERFACE:
      table: {
          name
      },
      index:{
          name,
          type: String (INDEX, PRIMARY, UNIQUE),
          columns: [
              {
                  name
              }
          ],
          comment: String || null
      }
*/
class CreateIndexCommand extends Command {
	constructor(table, index) {
		super("CreateIndex");
		this.index = index;
		this.table = table;
	}

	execute() {
		let ddl;
		if (this.index.type === "PRIMARY") {
			ddl = `ALTER TABLE \`${this.table.name}\` ADD PRIMARY KEY (`;
		} else {
			ddl = `CREATE ${this.index.type} `;
			if (this.index.type === "UNIQUE") ddl += "INDEX ";
			ddl += `\`${this.index.name}\` ON \`${this.table.name}\` (`;
		}

		// Index columns
		for (let i = 0; i < this.index.columns.length; i++) {
			ddl += `\`${this.index.columns[i].name}\``;
			if (i !== this.index.columns.length - 1) ddl += ", ";
		}
		ddl += ")";

		// Comment
		if (this.index.comment) {
			ddl += `\n COMMENT '${this.index.comment}' `;
		}

		return ddl;
	}
}

export default CreateIndexCommand;
