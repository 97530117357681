<template>
	<div ref="wrap" class="input-wrap recovery-edit-multiselect">
		<small>Datapoint headline</small>
		<div class="row">
			<input
				type="text"
				class="input name"
				placeholder="Headline"
				:value="name"
				@input="handleNameInput"
			/>
		</div>
		<small>Date</small>
		<div class="row">
			<input
				ref="day"
				type="text"
				class="input"
				placeholder="Day"
				:value="selectedDate.day"
				@input="handleInput($event, 'day')"
			/>
			<div>
				<multiselect-form
					class="recover-editdatapoint-select"
					:options="months"
					:model-value="multiselectProps.value"
					:placeholder="multiselectProps.placeholder"
					:searchable="true"
					@select="handleMonthSelect"
				/>
			</div>
			<input
				type="text"
				class="input"
				placeholder="Year"
				:value="selectedDate.year"
				@input="handleInput($event, 'year')"
			/>
		</div>
		<small>Time</small>
		<div class="row">
			<input
				type="text"
				class="input"
				placeholder="Hour"
				:value="selectedDate.hour"
				@input="handleInput($event, 'hour')"
			/>
			<input
				type="text"
				class="input"
				placeholder="Minute"
				:value="selectedDate.minute"
				@input="handleInput($event, 'minute')"
			/>
			<input
				type="text"
				class="input"
				placeholder="Second"
				:value="selectedDate.second"
				@input="handleInput($event, 'second')"
			/>
		</div>
		<div class="bottom-wrap">
			<h4>Description</h4>
			<div class="row textarea">
				<textarea
					maxlength="255"
					:value="description"
					placeholder="Click to input description"
					@input="handleDescription"
				></textarea>
				<!-- <span class="limiter">{{255 - this.description.length}}</span> -->
			</div>
			<div class="button-wrap">
				<div class="discard-button" @click="closeModernModalCompat">
					Discard
				</div>
				<div
					v-if="buttonActive"
					class="recovery-button"
					@click="createDatapoint"
				>
					Save changes
				</div>
				<div
					v-else
					v-tooltip="
						'Please fill out headline, date and time to edit datapoint.'
					"
					class="recovery-button inactive"
				>
					Edit Datapoint
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/api";
import { DateInstance } from "@/assets/js/dateHelperClass.ts";
const months = DateInstance.getDateLocaleData().monthsShort();
export default {
	data() {
		return {
			selectedDate: {
				day: "",
				month: "",
				year: "",
				hour: "",
				minute: "",
				second: "",
			},
			description: "",
			name: "",
			months,
		};
	},
	computed: {
		multiselectProps() {
			return {
				options: this.months,
				placeholder: "Month",
				value: this.selectedDate.month
					? this.months[this.selectedDate.month - 1]
					: "",
			};
		},
		buttonActive() {
			return (
				Object.values(this.selectedDate).filter((el) => !el).length === 0 &&
				this.name.length > 0
			);
		},
		datePart() {
			if (
				!this.selectedDate.year ||
				!this.selectedDate.month ||
				!this.selectedDate.day
			) {
				return "";
			}
			let day = this.selectedDate.day;
			if (day < 10 && ("" + day).length === 1) {
				day = "0" + day;
			}
			let month = this.selectedDate.month;
			if (month < 10 && ("" + month).length === 1) {
				month = "0" + month;
			}
			return `${this.selectedDate.year}-${month}-${day}`;
		},
		timePart() {
			if (
				!this.selectedDate.hour ||
				!this.selectedDate.minute ||
				!this.selectedDate.second
			) {
				return "";
			}
			let hour = this.selectedDate.hour;
			if (hour < 10 && hour.length === 1) {
				hour = "0" + hour;
			}
			let minute = this.selectedDate.minute;
			if (minute < 10 && minute.length === 1) {
				minute = "0" + minute;
			}
			let second = this.selectedDate.second;
			if (second < 10 && second.length === 1) {
				second = "0" + second;
			}
			return `${hour}:${minute}:${second}`;
		},
	},
	watch: {
		initialDate(val) {
			this.selectedDate = Object.assign({}, val);
		},
		initialDescription(val) {
			this.description = val;
		},
		initialName(val) {
			this.name = val;
		},
	},
	mounted() {
		if (this.$attrs.options.rcp_id !== null) {
			this.rcp_id = this.$attrs.options.rcp_id;
			this.name = this.$attrs.options.initialName;
			this.description = this.$attrs.options.initialDescription;
			this.selectedDate = Object.assign({}, this.$attrs.options.initialDate);
		}
	},
	methods: {
		handleMonthSelect(evt) {
			const val = evt.value;
			const value =
				DateInstance.getDateLocaleData().monthsShort().indexOf(val) + 1;
			this.selectedDate.month = value;
			this.handleDayValue();
		},
		handleInput(e, type) {
			switch (type) {
				case "day": {
					this.handleDayValue();
					return;
				}
				case "year": {
					if (!/^([12]\d{0,3})?$/.test(e.target.value)) {
						e.target.value = this.selectedDate.year;
						return;
					}
					this.handleDayValue();
					break;
				}
				case "hour": {
					if (!/^(([01]\d?)|(2[0-4]?))?$/.test(e.target.value)) {
						e.target.value = this.selectedDate[type];
						return;
					}
					break;
				}
				case "minute":
				case "second": {
					if (!/^([0-5]\d?)?$/.test(e.target.value)) {
						e.target.value = this.selectedDate[type];
						return;
					}
					break;
				}
			}
			this.selectedDate[type] = e.target.value;
		},
		handleDayValue() {
			let value = this.$refs.day.value;
			if (!/^((0[1-9]?)|([12]\d?)|(3[01]?))?$/.test(value)) {
				this.$refs.day.value = this.selectedDate.day;
				return;
			}
			if (this.selectedDate.month) {
				const max_date = new Date(
					this.selectedDate.year,
					this.selectedDate.month,
					0,
				).getDate();
				if (Number(value) > max_date) {
					value = max_date;
				}
			}
			this.selectedDate.day = value;
		},
		// overflowFix () {
		//   this.$refs.wrap.parentElement.style = "overflow: visible;";
		// },
		handleDescription(e) {
			if (
				(this.description.length === 255 && e.data !== null) ||
				/[\n\r\t]/.test(e.target.value)
			) {
				e.target.value = this.description;
				return;
			}
			this.description = e.target.value;
		},
		handleNameInput(e) {
			if (this.name.length === 35 && e.data !== null) {
				e.target.value = this.name;
				return;
			}
			this.name = e.target.value;
		},
		async createDatapoint() {
			if (
				!this.buttonActive ||
				this.description.length > 255 ||
				this.name.length > 35
			) {
				return;
			}
			try {
				await api.createDatapoint({
					name: this.name,
					desc: this.description,
					recoverytime: this.datePart + " " + this.timePart,
				});
				this.closeModernModalCompat();
				this.openToastStorage({
					data: {
						type: "success",
						text: "Datapoint created.",
						duration: 1500,
					},
				});
			} catch (error) {
				console.log(error);
				this.openToastStorage({
					data: {
						type: "error",
						text: "Error creating datapoint",
						duration: 5000,
					},
				});
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import "../recoveryVariables";

.recovery-edit-multiselect {
	.multiselect {
		height: 44px;
		width: 150px;
		box-sizing: border-box;
		border-radius: 4px !important;
		font-size: 16px;
		font-weight: 600;
		border: 1px solid #494949;
		padding: 0 10px;
		background: green;

		.multiselect-search {
			text-indent: 10px;
		}
	}
}
.row {
	display: flex;
	gap: 25px;
	max-width: 100%;
	.input {
		width: 150px;
		height: 44px;
		background: $input-alt-bg;
		border: 1px solid #494949;
		box-sizing: border-box;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 600;
		&.name {
			width: 100%;
		}
	}
	textarea {
		// background-color: $input-background;
		padding: 0;
		border-radius: 0;
		width: 100%;
		min-width: 325px;
		min-height: 200px;
		max-width: 50vw;
		max-height: 40vh;
		background: transparent;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 26px;
		letter-spacing: 0;
		color: #aaa;
		resize: none;
	}
	textarea:focus {
		background: $input-alt-bg;
		resize: both;
	}
}
.textarea {
	position: relative;
}

.input-wrap {
	// height: 700px;
	padding: 20px 30px 58px;
	small {
		display: block;
		margin: 7px 0;
		color: #7d7d7d;
		&.limiter {
			position: absolute;
			bottom: 10px;
			right: 10px;
			font-size: 15px;
			margin: 0;
		}
	}
}
.bottom-wrap {
	border-top: solid 1px #3f3f42;
	padding-top: 40px;
	margin-top: 30px;
	h4 {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 25px;
	}
}
.button-wrap {
	margin-top: 70px;
	display: flex;
	justify-content: flex-end;
	gap: 30px;
}
.recovery-button {
	background: #1e75fe;
	width: 150px;
	height: 45px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.5s ease;
}

.inactive {
	background: #315a9c;
	cursor: not-allowed;
}
.discard-button {
	height: 45px;
	color: #f83866;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	font-size: 16px;
}
</style>
