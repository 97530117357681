export const FileSubKeysTS = {
	api: ["GET", "POST", "PUT", "DELETE", "HEAD", "PATCH", "OPTIONS"],
	wss: ["onconnect", "onmessage", "ondisconnect"],
	shared: ["notes"],
	default: ["__default"],
} as const;

export type TValidApiTypes = (typeof FileSubKeysTS)["api"][number];
export type TValidWSTypes = (typeof FileSubKeysTS)["wss"][number];
export type TValidSharedTypes = (typeof FileSubKeysTS)["shared"][number];
export type TValidDefaultTypes = (typeof FileSubKeysTS)["default"][number];
export type TValidFileActions =
	| TValidApiTypes
	| TValidWSTypes
	| TValidSharedTypes
	| TValidDefaultTypes;

export type TFileType = "EAP" | "WSS" | "LIB" | "APL" | "WSL" | "BIN" | "TXT";
export const prettyFileType: Record<TFileType, string> = {
	EAP: "API - HTTP",
	WSS: "API - Websocket",
	LIB: "Library",
	APL: "API - HTTP Library", // Not used
	WSL: "API - Websocket Library", // Not used
	BIN: "Binary File",
	TXT: "Text",
};

export const SubKeysPerType = {
	API: [...FileSubKeysTS.api, ...FileSubKeysTS.shared],
	EAP: [...FileSubKeysTS.api, ...FileSubKeysTS.shared],
	WSS: [...FileSubKeysTS.wss, ...FileSubKeysTS.shared],
	TXT: FileSubKeysTS.default,
};
