import Command from "../AbstractCommand";

/*
    parameters: {
      trigger: {
        name: String,
        action: String,
        event: String
      },
      tableName: String
    }
 */

class CreateTriggerCommand extends Command {
	constructor(trigger, table) {
		super("CreateTrigger");
		this.trigger = trigger;
		this.tableName = table.name;
	}

	execute() {
		let triggerDdl =
			"CREATE TRIGGER `" +
			this.trigger.name +
			"` " +
			this.trigger.event +
			" ON `" +
			this.tableName +
			"`" +
			"\nFOR EACH ROW " +
			"\nBEGIN" +
			"\n" +
			this.trigger.action +
			"\nEND";

		let data = {
			type: "trigger",
			name: this.trigger.name,
			actionTiming: this.trigger.event.split(" ")[0],
			actionType: this.trigger.event.split(" ")[1],
			event: this.trigger.event,
			action: this.trigger.action,
			ddl: triggerDdl,
			table: this.tableName,
		};
		// return triggerDdl
		return data;
	}
}

export default CreateTriggerCommand;
