import Commander from "./AbstractCommander";

class PrioritiesCommander extends Commander {
	/*
  PARAMS:
    priorities: [String]
   */
	// public
	constructor(priorities) {
		super();
		this.priorities = priorities;
	}

	// public
	generateDDL() {
		this.sortByPriority(this.commandsQueue);
		// let ddl = ''
		let ddl = [];
		for (const action of this.commandsQueue) {
			// ddl += action.createStatement() + '\n'
			ddl.push(action.createStatement());
		}
		return ddl;
	}

	// private
	getPriority(priority) {
		for (let i = 0; i < this.priorities.length; i++)
			if (this.priorities[i] === priority) return i;
		throw new Error("Invalid priorety: " + priority);
	}

	// private
	sortByPriority(queue) {
		for (let i = 0; i < queue.length; i++) {
			for (let j = queue.length - 1; j > i; j--) {
				if (
					this.getPriority(queue[j].priority) <
					this.getPriority(queue[i].priority)
				) {
					let cur = queue[j];
					queue[j] = queue[i];
					queue[i] = cur;
				}
			}
		}
	}
}

export default PrioritiesCommander;
