function indexOf(el, prop, arr) {
	return arr.findIndex((element) => element[prop] === el[prop]);
}

const Index = {
	findIndex: (index, indexes) => {
		let i = indexOf(index, "name", indexes);
		return i === -1 ? null : indexes[i];
	},

	isChanged(indexInitial, index) {
		for (let prop in index) {
			if (typeof index[prop] === "object") {
				// checking if there is a change on indexed columns
				for (let i = 0; i < indexInitial.columns.length; i++) {
					if (indexOf(indexInitial.columns[i], "name", index.columns) === -1) {
						return true;
					}
				}

				for (let i = 0; i < index.columns.length; i++) {
					if (indexOf(index.columns[i], "name", indexInitial.columns) === -1) {
						return true;
					}
				}
			} else {
				if (index[prop] !== indexInitial[prop]) {
					return true;
				}
			}
		}
		return false;
	},

	getType(index) {
		if (index.type === "PRIMARY") return "PRIMARY KEY";
		if (index.type === "INDEX") return "INDEX";
		return index.type + " INDEX";
	},

	findIndexedColumns(index) {
		return index.columns.map((column) => "`" + column.name + "`").join(", ");
	},
};

export default Index;
