import tutorialThumb from "@/assets/image/profile/tutorial/editorTutorial.png";

export default {
	config: {
		tutorialThumb,
	},
	steps: [
		{
			target: "#app",
			header: {
				title: "MARS Tutorial",
			},
			content:
				"Hi there! We put together a tutorial for MARS and we'd appreciate your your feedback. Send your feedback to <strong>support@mars-server.net</string>",
			params: {
				enableScrolling: true,
				placement: "auto",
				highlight: false,
				modifiers: [
					{
						name: "flip",
						options: {
							fallbackPlacements: ["top", "right"],
						},
					},
					{
						name: "offset",
						options: {
							offset: ({ placement, reference, popper }) => {
								return [0, -500];
							},
						},
					},
				],
			},
		},
		{
			target: ".project-view > .content",
			header: {
				title: "Project list",
			},
			content: "Browse the list of <strong>your projects</strong>",
			params: {
				enableScrolling: false,
				placement: "auto",
				modifiers: [
					{
						name: "flip",
						options: {
							fallbackPlacements: ["top", "right"],
						},
					},
					{
						name: "offset",
						options: {
							offset: ({ placement, reference, popper }) => {
								return [0, -500];
							},
						},
					},
				],
			},
		},
		{
			target: ".project-view .change-view",
			content: "Toggle the view from list to grid and vice-versa",
			// header: {

			// }
		},
		// {
		//     target: 'aside > .bottom',
		//     header: {
		//         title: 'Project mini sidebar'
		//     },
		//     content: 'Project mini sidebar'
		// }
		// {
		//   target: '[data-v-step="2"]',
		//   header: {
		//     title: 'Project list'
		//   },
		//   content: 'Try it, you\'ll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.',
		//   params: {
		//     placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
		//   }
		// }
	],
};
