import { type AxiosResponse } from "axios";
import type * as filemanager from "@/modules/core/file-manager/filemanager.js";
import { infoConsole } from "@/assets/js/helpers";

export function saveFile() {}

export function mergeFileTree(
	response: AxiosResponse,
	// @ts-expect-error
	marsFileMeta: filemanager.MarsFileMeta,
): void {
	if (response.status === 200) {
		const nfData = {
			...response.data.changedFiles[0],
		};

		const payload = {
			children: nfData.children,
			id: nfData.newId,
			oldId: nfData.id,
			fullPath: nfData.path,
			type: nfData.type,
			options: nfData.options,
		};
		if (marsFileMeta && "mergeMarsFile" in marsFileMeta) {
			marsFileMeta.mergeMarsFile(payload);
		} else {
			infoConsole("[X] - Error saving file 2", "error", payload);
		}
	}
}
