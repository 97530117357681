<template lang="pug">
.forgot-pass-form
	label
		span.lbl Email
		.input-field
			input#reset_email.flat.flat-auth.form-control(
				v-model.trim="user.email",
				autofocus,
				type="text",
				required,
				name="reset_email",
				placeholder="Email",
				@keydown.enter="resetEmail"
			)
	div
		span You'll receive an email with instructions to reset your password.
		span Please check your spam folder.

	.form-group
		button.btn-acc-alt(:disabled="!user.email", @click="resetEmail") {{ $t("button.reset") }}
	router-link(:to="{ name: 'LoginComp' }")
		span Back to login
</template>

<script>
import { mapActions } from "vuex";
export default {
	data() {
		return {
			user: {
				email: "",
			},
		};
	},
	mounted() {
		this.$el.querySelector("input")?.focus();
	},
	methods: {
		...mapActions({
			forgotPass: "moduleAuth/forgotPass",
		}),
		async resetEmail() {
			try {
				const res = await this.forgotPass(this.user);
				if (res.data?.message) {
					this.openToastWarningMsg(res.data.message);
					// this.$router.push({
					//   name: 'LoginComp'
					// })
				}
			} catch (err) {
				console.warn(err.message);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.forgot-pass-form {
	@include auth-form;

	.form-group {
		margin: 40px 0 20px;
		text-align: center;
	}

	a {
		color: $green;
		text-align: center;
	}

	button {
		height: 46px;
		width: 145px;
	}
}
</style>
