import Command from "../AbstractCommand";

/*
  INPUTS
    table - processable schema object
    table used fields example:
      table: {
        name: String
      }
 */

class DropTableCommand extends Command {
	constructor(table) {
		super("DropTable");
		this.tableName = table.name;
	}

	execute() {
		return `DROP TABLE IF EXISTS ${this.tableName}`;
	}
}

export default DropTableCommand;
