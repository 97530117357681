import tutorialThumb from "@/assets/image/profile/tutorial/apiTutorial.png";

export default {
	config: {
		dependsOn: [
			{
				group: "editor",
				subGroup: "first-api",
			},
		],
		tutorialThumb,
	},
	steps: [
		{
			target: ".project-view > .content .project-list",
			// header: {
			//     title: 'Project list'
			// },
			content:
				"Now we will create our first API, click any project to proceed.",
			params: {
				enableScrolling: false,
				placement: "bottom",
				// highlight: false,
			},
			buttons: {
				previous: false,
				next: false,
			},
		},
		{
			// Dummy
			content: "Editor Tree",
			params: {
				enableScrolling: false,
				placement: "top",
				highlight: false,
			},
		},
	],
};
