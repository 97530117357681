<script setup lang="ts">
const spinnerSize = ref({});
const spinnerWrapRef = ref<HTMLElement | null>(null);

function calcWidHeig() {
	if (spinnerWrapRef.value) {
		const el = spinnerWrapRef.value;
		const width = el.offsetWidth;
		const height = el.offsetHeight;
		const calcPerc = 0.07;
		spinnerSize.value =
			width > height
				? {
						width: `${height * calcPerc}px`,
						height: `${height * calcPerc}px`,
				  }
				: {
						width: `${width * calcPerc}px`,
						height: `${width * calcPerc}px`,
				  };
	} else {
		spinnerSize.value = {};
	}
}

onMounted(() => {
	calcWidHeig();
});
</script>

<template lang="pug">
.spinner-block(ref="spinnerWrapRef")
	.sk-circle(:style="spinnerSize")
		.sk-circle1.sk-child
		.sk-circle2.sk-child
		.sk-circle3.sk-child
		.sk-circle4.sk-child
		.sk-circle5.sk-child
		.sk-circle6.sk-child
		.sk-circle7.sk-child
		.sk-circle8.sk-child
		.sk-circle9.sk-child
		.sk-circle10.sk-child
		.sk-circle11.sk-child
		.sk-circle12.sk-child
</template>

<style lang="scss" scoped>
.spinner-block {
	position: fixed;
	background: #000000ad;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sk-circle {
	width: 40px;
	height: 40px;
	position: relative;
}

.sk-circle .sk-child {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.sk-circle .sk-child::before {
	content: "";
	display: block;
	margin: 0 auto;
	width: 15%;
	height: 15%;
	background-color: white;
	border-radius: 100%;
	animation: sk-circle-bounce-delay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
	transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
	transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
	transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
	transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
	transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
	transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
	transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
	transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
	transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
	transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
	transform: rotate(330deg);
}

.sk-circle .sk-circle2::before {
	animation-delay: -1.1s;
}

.sk-circle .sk-circle3::before {
	animation-delay: -1s;
}

.sk-circle .sk-circle4::before {
	animation-delay: -0.9s;
}

.sk-circle .sk-circle5::before {
	animation-delay: -0.8s;
}

.sk-circle .sk-circle6::before {
	animation-delay: -0.7s;
}

.sk-circle .sk-circle7::before {
	animation-delay: -0.6s;
}

.sk-circle .sk-circle8::before {
	animation-delay: -0.5s;
}

.sk-circle .sk-circle9::before {
	animation-delay: -0.4s;
}

.sk-circle .sk-circle10::before {
	animation-delay: -0.3s;
}

.sk-circle .sk-circle11::before {
	animation-delay: -0.2s;
}

.sk-circle .sk-circle12::before {
	animation-delay: -0.1s;
}

@keyframes sk-circle-bounce-delay {
	0%,
	80%,
	100% {
		transform: scale(0);
	}

	40% {
		transform: scale(1);
	}
}

@keyframes sk-circle-bounce-delay {
	0%,
	80%,
	100% {
		transform: scale(0);
	}

	40% {
		transform: scale(1);
	}
}
</style>
