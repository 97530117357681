import api from "@/api";
import { sortListFileTree } from "@/helpers/helpers";
import { DateInstance } from "@/assets/js/dateHelperClass.ts";

import filemanager from "@/modules/core/file-manager/filemanager.js";
import { useFileStore } from "./fileStore";
const fileStore = useFileStore();

const Vue = {
	// Simulating vue2 set & delete
	set: (obj, prop, value) => {
		if (obj) {
			obj[prop] = value;
		} else {
			console.warn("Object not specified");
		}
	},
	delete: (obj, prop) => {
		if (obj) {
			delete obj[prop];
		} else {
			console.warn("Object not specified");
		}
	},
};

// function concatFilePath(file) {
// 	// const file = { ...el };
// 	// delete file.children;
// 	return file;
// }

// function flatDeep(arr = [], d = 2) {
// 	if (d > 0) {
// 		return arr.flatMap((val) => {
// 			if (Array.isArray(val.children) && val.children.length) {
// 				const fdArr = flatDeep(val.children, d - 1);
// 				const valCh = concatFilePath(val);
// 				return [valCh, ...fdArr];
// 			} else {
// 				return concatFilePath(val);
// 			}
// 		});
// 	}
// 	return arr;
// }

function transformTreeData(arr = []) {
	return arr.map((item) => {
		return new filemanager.MarsFileMeta(item);
	});
}

export default {
	namespaced: true,
	state: {
		treeList: [],
		// flatTreeList: [],
		isTreeLoaded: false,
		allLockedStatePaths: {
			me: {},
			other: {},
		},
		multiFileSelectionPoints: {},
		multiFileSelectionArr: [],
		multiPendingPaths: [],
		intervalCheckingLockedFiles: null,
	},
	getters: {
		getFileTree: (state, getters, rootState, rootGetters) => {
			return {
				isOpen: true,
				fullPath: null,
				children: state.treeList,
			};
		},
		getFlatFullTree: () => fileStore.flatTree.value,
		getFlatTreeView: (state, getters) => fileStore.getFlatTreeView.value,
		getFlatSortedTreeView: (state, getters) => {
			const tree = [...getters.getFlatTreeView];
			const rootElements = tree.filter((item) => !item.fullPath.includes("/"));
			const rootSortedMut = sortListFileTree(rootElements);
			const childElements = tree.filter((item) => item.fullPath.includes("/"));
			const childProcPaths = {};
			childElements.reduce((acc, curr) => {
				const parentFp = curr.fullPath.split("/").slice(0, -1).join("/");
				if (Object.prototype.hasOwnProperty.call(childProcPaths, parentFp)) {
					return acc;
				} else {
					const allFilesFromPath = childElements.filter((item) => {
						const parentFilterFp = item.fullPath
							.split("/")
							.slice(0, -1)
							.join("/");
						return parentFilterFp === parentFp;
					});

					childProcPaths[parentFp] = sortListFileTree(allFilesFromPath);
				}
				return acc;
			}, []);
			for (const [key, val] of Object.entries(childProcPaths)) {
				const index = rootSortedMut.findIndex((item) => key === item.fullPath);
				rootSortedMut.splice(index + 1, 0, ...val);
			}

			return rootSortedMut;
		},
		getFileFromTree: () => (path) => fileStore.getFileFromTree(path),
		getFileLockedForMe: (state) => (path) => {
			return path ? state.allLockedStatePaths.other[path] || null : null;
		},
		getFileLockedByMe: (state) => (path) => {
			return path ? state.allLockedStatePaths.me[path] || null : null;
		},
		getFileLockedGeneral: (state, getters) => (path) => {
			return path
				? getters.getFileLockedForMe(path) || getters.getFileLockedByMe(path)
				: null;
		},
		getNumberPathsOpen: () => fileStore.treeOpenPaths.value.size,
		getPathIsOpen: () => (p) => fileStore.isPathExpanded(p?.fullPath || ""),
		getMultiSelectionPaths: (state) => {
			return state.multiFileSelectionArr.map((file) => file.fullPath);
		},
		getMultiSelectionPoints: (state) => {
			const mfs = state.multiFileSelectionPoints;
			if (mfs?.start && mfs?.end) {
				return [mfs.start.fullPath, mfs.end.fullPath];
			} else if (mfs?.start) {
				return [mfs.start.fullPath];
			}
			return [];
		},
	},
	mutations: {
		SET_TREE_DATA(state, treeDataResponse) {
			const treeData = transformTreeData(treeDataResponse, 100);
			fileStore.createTreeDataInstances(treeDataResponse);
			state.treeList = treeData;
			// state.flatTreeList = flatDeep(treeData, 100);
			state.isTreeLoaded = true;
		},
		SET_EDITOR_TREE_LOADING(state, isLoading) {
			state.isTreeLoaded = !isLoading;
		},
		CLEAR_TREE_DATA(state, data) {
			fileStore.clearTreePaths();
			fileStore.clearTree();
			fileStore.lastUsedLang = "";

			state.treeList = [];
			state.isTreeLoaded = false;
			state.allLockedStatePaths = {
				me: {},
				other: {},
			};
			state.multiFileSelectionPoints = {};
			state.multiFileSelectionArr = [];
			state.multiPendingPaths = [];
			state.intervalCheckingLockedFiles = null;
		},
		MERGE_TREE_DATA_BY_ID(state, treeFile) {
			const findFileWithChildren = (treeList, oldId) => {
				for (const val of treeList) {
					if (val.id === oldId) {
						return val;
					} else if (Array.isArray(val.children) && val.children.length) {
						const foundVal = findFileWithChildren(val.children, oldId);
						if (foundVal) {
							return foundVal;
						}
					}
				}
				return null;
			};

			if (!import.meta.env.PROD) {
				console.time("[DEV] file-tree-lookup");
			}
			const foundObj = findFileWithChildren(state.treeList, treeFile.oldId);
			if (foundObj) {
				for (const [key, val] of Object.entries(treeFile)) {
					Vue.set(foundObj, key, val);
				}
			} else {
				console.warn(
					">>> [File Merge] File not found. Aborting.",
					treeFile.oldId,
					treeFile.name,
				);
			}
			if (!import.meta.env.PROD) {
				console.timeEnd("[DEV] file-tree-lookup");
			}
		},
		ADD_SINGLE_FILE_SELECTION_POINTS(state, params) {
			const { item } = params;
			const msp = state.multiFileSelectionPoints;
			Vue.set(state.multiFileSelectionPoints, "isSingle", true);
			// CTRL | META
			if (!msp.start) {
				Vue.set(state.multiFileSelectionPoints, "start", item);
			} else if (!msp.startNext || msp.end) {
				// Only the first one
				Vue.set(state.multiFileSelectionPoints, "startNext", item);
			}

			const manageCtrlNode = () => {
				const nodeExistIndex = state.multiFileSelectionArr.findIndex(
					(fileSel) => fileSel.fullPath === item.fullPath,
				);
				if (nodeExistIndex === -1) {
					// Add
					state.multiFileSelectionArr.push(item);
				} else {
					// Remove
					state.multiFileSelectionArr.splice(nodeExistIndex, 1);
				}
			};

			manageCtrlNode();
		},
		ADD_MULTI_FILE_SELECTION_POINTS(state, params) {
			const { item } = params;
			const msp = state.multiFileSelectionPoints;
			Vue.set(state.multiFileSelectionPoints, "isSingle", false);
			// SHIFT
			if (msp.startNext) {
				// Saved from previous CTRL \ META
				Vue.set(state.multiFileSelectionPoints, "start", item);
				Vue.delete(state.multiFileSelectionPoints, "startNext");
			}

			if (msp.start || msp.end) {
				Vue.set(state.multiFileSelectionPoints, "end", item);
			} else {
				Vue.set(state.multiFileSelectionPoints, "start", item);
			}
		},
		CLEAR_MULTI_FILE_SELECTION(state, params) {
			state.multiFileSelectionArr = [];
			state.multiFileSelectionPoints = {};
		},
		SET_SHIFT_MULTI_FILES(state, data) {
			state.multiFileSelectionArr = data;
		},
		SET_MOVE_PENDING_KEYBOARD(state, data) {
			const pendingPaths = [];
			for (const itemSelected of state.multiFileSelectionArr) {
				pendingPaths.push(itemSelected.fullPath);
			}

			state.multiPendingPaths = pendingPaths;
		},
		CLEAR_MOVE_PENDING_KEYBOARD(state, data) {
			state.multiPendingPaths = [];
		},

		CLEAR_FILE_LOCK(state, path) {
			Vue.delete(state.allLockedStatePaths.other, path);
		},
		SET_FILE_LOCK(state, payload) {
			Vue.set(state.allLockedStatePaths.other, payload.lock_path, payload);
		},
		SET_FILE_LOCK_BY_ME(state, payload) {
			Vue.set(state.allLockedStatePaths.me, payload.lock_path, payload);
		},
		CLEAR_FILE_LOCK_BY_ME(state, path) {
			Vue.delete(state.allLockedStatePaths.me, path);
		},
		SET_LOCKING_INTERVAL(state, interval = 2000) {
			state.intervalCheckingLockedFiles = interval;
		},
		CLEAR_LOCKING_INTERVAL(state, payload) {
			clearInterval(state.intervalCheckingLockedFiles);
			state.intervalCheckingLockedFiles = null;
		},
	},
	actions: {
		async fetchEditorTree({ commit, dispatch }, isLoadSpinner = true) {
			let timeout = null;
			if (isLoadSpinner) {
				timeout = setTimeout(() => {
					commit("SET_EDITOR_TREE_LOADING", true);
				}, 200);
			}
			await dispatch("getTreeData");
			if (isLoadSpinner) {
				clearTimeout(timeout);
				commit("SET_EDITOR_TREE_LOADING", false);
			}
		},
		async getTreeData({ commit, dispatch }, payload) {
			try {
				const res = await api.getTreeData(payload);
				const treeDataArr = res.data?.result?.dir;
				if (Array.isArray(treeDataArr)) {
					// treeDataArr.length - Don't check for this, it can't clear tree;
					commit("SET_TREE_DATA", treeDataArr);
				}
				return true;
			} catch (err) {
				console.warn(err.message);
				dispatch("moduleToast/openToastWrapStore", {
					type: "error",
					msg: "Can't fetch file system data",
				});
			}
			return false;
		},
		clearTreeData({ commit }, payload) {
			commit("CLEAR_TREE_DATA");
		},
		mergeTreeDataById({ commit }, payload) {
			// if (!payload.path) {
			if (!payload.fullPath) {
				console.error("Invalid file structure");
				return;
			}

			// This will merge some file parts
			// const splitPath = payload.path.split("/");
			const splitPath = payload.fullPath.split("/");
			const treeFile = {
				oldId: payload.id,
				id: payload.newId,
				options: payload.options,
				// fullPath: payload.path,
				fullPath: payload.fullPath,
				type: payload.type,
				name: splitPath[splitPath.length - 1],
				errors: payload.errors || 0,
			};
			commit("MERGE_TREE_DATA_BY_ID", treeFile);
		},
		setPathExpand({ state, getters, commit }, fullPath) {
			fileStore.manageOpenPath(fullPath);
		},
		clearPathExpand({ commit }, payload) {
			fileStore.clearTreePaths();
		},
		expandTreeParentPaths({ state, getters, commit }, fullPath) {
			if (!fullPath) {
				console.warn("No full path for file");
				return;
			}
			const fullPathSplit = fullPath.split("/");
			const recSetup = () => {
				let tempPath = "";
				for (const partPath of fullPathSplit) {
					tempPath += tempPath ? `/${partPath}` : partPath;

					const payload = {
						fullPath: tempPath,
						index: 0,
					};
					// Open if not already
					if (!getters.getPathIsOpen(payload)) {
						fileStore.manageOpenPath(payload.fullPath, true);
					}
				}
			};

			recSetup();
		},
		manageMultiFileSelPoints({ state, commit }, params) {
			if (params) {
				if (params.isSingle) {
					commit("ADD_SINGLE_FILE_SELECTION_POINTS", params);
				} else if (params.isSingle) {
					console.warn("Invalid file selection points");
				} else {
					commit("ADD_MULTI_FILE_SELECTION_POINTS", params);
				}
			} else {
				const hasSelArr =
					Array.isArray(state.multiFileSelectionArr).length !== 0;
				const hasSelPoints =
					Object.keys(state.multiFileSelectionPoints).length !== 0;
				if (hasSelArr || hasSelPoints) {
					// Clear only if has something already
					// (It will probably invoke always because of adding new on select)
					commit("CLEAR_MULTI_FILE_SELECTION", params);
				}
			}
		},
		manageNodeDataFromShiftSel({ state, commit }, payload) {
			commit("SET_SHIFT_MULTI_FILES", payload);
		},
		clearNodeDataShiftSel({ commit }, payload) {
			// Not implemented
			commit("CLEAR_MULTI_FILE_SEL");
		},
		addPathsPendingMove({ commit }, payload) {
			commit("SET_MOVE_PENDING_KEYBOARD", payload);
		},
		clearPathsPendingMove({ commit }, payload) {
			commit("CLEAR_MOVE_PENDING_KEYBOARD", payload);
		},
		setlastUsedLangFromCreate({ commit }, langSelected) {
			fileStore.lastUsedLang = langSelected;
		},
		clearFileLock({ commit }, path) {
			commit("CLEAR_FILE_LOCK", path);
		},
		setFileLockedProp({ commit, dispatch }, payload) {
			commit("SET_FILE_LOCK", payload);
			dispatch("setFileLockingInterval");
		},
		addToEditorLockedArray({ commit, dispatch }, payload) {
			commit("SET_FILE_LOCK_BY_ME", payload);
		},
		removeFromEditorLockedArray({ commit, dispatch }, path) {
			commit("CLEAR_FILE_LOCK_BY_ME", path);
		},
		setFileLockingInterval({ state, commit }, interval = 2000) {
			if (interval) {
				const startInterval = () => {
					const intervalNum = setInterval(() => {
						const lockedFilesEntries = Object.entries(
							state.allLockedStatePaths.other,
						);
						if (lockedFilesEntries.length === 0) {
							// Stop checking if no locked files [It starts when some file gets locked]
							commit("CLEAR_LOCKING_INTERVAL");
						} else {
							// Check date logic
							for (const [key, val] of lockedFilesEntries) {
								const diff = DateInstance.getDateInstance(val.lock_to)
									.local()
									.diff(DateInstance.getDateInstance(), "seconds");
								const isDateAheadOfLockedPeriod = diff < 0;
								if (isDateAheadOfLockedPeriod) {
									commit("CLEAR_FILE_LOCK", key);
								}
							}
						}
					}, interval);
					commit("SET_LOCKING_INTERVAL", intervalNum);
				};
				if (state.intervalCheckingLockedFiles) {
					// Clears dups
					commit("CLEAR_LOCKING_INTERVAL");
					startInterval();
				} else {
					startInterval();
				}
			} else {
				commit("CLEAR_LOCKING_INTERVAL");
			}
		},
		// API
		deleteTreeFile({ commit }, payload) {
			return api.deleteTreeFile(payload);
		},
		deleteAllTreeFiles({ commit }, payload) {
			return api.deleteAllTreeFiles(payload);
		},
		renameMoveTreeFile({ commit }, payload) {
			return api.renameMoveTreeFile(payload);
		},
		async getSearchTree({ commit }, payload) {
			commit("SET_EDITOR_TREE_LOADING", true);
			try {
				const res = await api.getSearchTree(payload);
				return res;
			} catch (err) {
				return err;
			} finally {
				commit("SET_EDITOR_TREE_LOADING", false);
			}
		},
		checkPathName({ commit }, payload) {
			return api.checkPathName(payload);
		},
		postGetPreviousVersions({ commit }, payload) {
			return api.postGetPreviousVersions(payload);
		},
		postPreviousVersionFile({ commit }, payload) {
			return api.postPreviousVersionFile(payload);
		},
		postCopyFileNodeTree: ({ commit }, payload) =>
			api.copyFileNodeTree(payload),
		postSiteRestart: ({ commit }, payload) => api.postSiteRestart(payload),
	},
};
