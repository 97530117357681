<template>
	<div
		class="checkbox-wrap"
		:class="[
			{ checked: getChecked, disabled: options.disabled },
			options.theme,
		]"
	>
		<div class="checkbox-group" @click="changeRefInput">
			<label>
				<span
					v-if="getLeftText"
					class="left"
					:class="options.labelClass"
					for="checkbox-input"
				>
					{{ getLeftText }}
				</span>
				<span
					class="checkbox-input"
					:class="{ checked: getChecked }"
					:tabindex="tabIndexBasedOnDisabled"
					@keyup.space="changeRefInput"
				>
					<template v-if="isCheckbox">
						<div class="checkbox-inner-box">
							<svg width="16" height="14" viewBox="0 0 16 14">
								<path d="M2 8.5L6 12.5L14 1.5" />
							</svg>
						</div>
					</template>
					<template v-if="!isCheckbox">
						<div class="toggle-wrap">
							<div class="toggle">
								<div class="switch"></div>
							</div>
						</div>
					</template>
				</span>
				<span
					v-if="getRightText"
					class="right"
					:class="options.labelClass"
					for="checkbox-input"
				>
					{{ getRightText }}
				</span>
			</label>
		</div>

		<input
			ref="input"
			class="hidden"
			:style="{ visibility: `hidden`, position: `absolute` }"
			:checked="getChecked"
			:disabled="options.disabled"
			type="checkbox"
			@change="changeVal"
		/>
	</div>
</template>

<script>
// << Manual >>
// Options contain: {}
//    isCheckbox: true | for checkbox ; false | for switch toggle
//    text: '' | for left side text
//    rightText: '' | for right side text
//    checked: false | checkbox value
//
// Alternatively... use prop checked if you only want to use checkbox vmodel

// Emits: @change { {evt, checked} }
import inputCompMixin from "../inputCompMixin";
export default {
	mixins: [inputCompMixin],
	emits: ["change"],
	computed: {
		isCheckbox() {
			return this.options?.isCheckbox !== false;
		},
	},
	methods: {
		changeVal(evt) {
			const isChecked = evt.target.checked;

			if (!this.isDirectChecked) {
				this.options.checked = isChecked;
			}

			this.$emit("change", {
				evt,
				checked: isChecked,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.checkbox-wrap {
	$toggle-width: 40px;
	$switch-width: 18px;
	$dark-back: #1f2329;

	display: inline-flex;

	.checkbox-group {
		display: inline-flex;
		align-items: center;
		user-select: none;

		.checkbox-input {
			min-width: 20px;
			height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			position: relative;
			cursor: pointer;

			.checkbox-inner-box {
				background: $dark-back;
				border: 1px solid rgb(87 87 87 / 0.643);
				border-radius: 4px;
				padding: 2px 2px 2px 3px;
				width: 20px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 1s ease;

				svg {
					opacity: 0;
				}
			}

			.toggle-wrap {
				width: $toggle-width;
				padding: 0 5px;
				height: 60%;
				position: relative;

				.toggle {
					background: #3f4246;
					width: 100%;
					height: 100%;
					border-radius: 8px;
					display: flex;
					align-items: center;

					.switch {
						width: $switch-width;
						height: $switch-width;
						background: white;
						border: 1px solid #34373d45;
						position: absolute;
						left: 0;
						border-radius: 50%;
						transition: all 0.5s ease;
					}
				}
			}

			&.checked {
				.checkbox-inner-box {
					background: rgb(39 39 39);

					svg {
						opacity: 1;
						transition: opacity 0.2s ease;
						fill: none;

						path {
							stroke: $main-accent-color;
							stroke-width: 2.5;
							stroke-linecap: round;
							stroke-linejoin: round;
						}
					}
				}

				.toggle-wrap {
					.toggle {
						background: lighten($color: $dark-back, $amount: 10);

						.switch {
							// background: lighten($color: #d34849, $amount: 5);
							background: $main-accent-color;
							left: calc(#{$toggle-width} - #{$switch-width});
						}
					}
				}
			}
		}

		label {
			display: flex;
			gap: 10px;
			user-select: none;
			cursor: pointer;
			align-items: center;
		}

		label.left {
			margin-right: 8px;
		}

		label.right {
			margin-left: 8px;
		}
	}

	&.disabled {
		pointer-events: none;
	}

	&.theme-alt {
		.checkbox-group {
			.checkbox-input {
				&.checked {
					.checkbox-inner-box {
						background: $green;

						svg {
							path {
								stroke: #212123;
							}
						}
					}
				}
			}
		}
	}
}
</style>
