<script setup lang="ts">
import { useResizeObserver, type UseResizeObserverReturn } from "@vueuse/core";

const props = withDefaults(
	defineProps<{
		name?: string;
		size?: number;
	}>(),
	{
		name: "",
		size: 0,
	},
);
defineEmits(["click"]);
let resizeObserver: UseResizeObserverReturn | null = null;
const dynamicFontSize = ref<string>("");
const gravatarWrapRef = ref<HTMLElement | null>(null);

const getAvatarStyle = computed(() => {
	return {
		background: stringToHslColor(parsedInitials.value),
	};
});
const getTextStyle = computed(() => {
	const unit = "px";
	let calcSize = `14${unit}`;
	if (props.size) {
		calcSize = `${props.size}${unit}`;
	} else if (dynamicFontSize.value) {
		calcSize = dynamicFontSize.value;
	}

	return {
		fontSize: calcSize,
	};
});
const parsedInitials = computed(() => {
	if (props.name.length > 3) {
		let inStr = "";
		if (props.name.includes(" ")) {
			inStr = props.name
				.split(" ")
				.map((it) => it.slice(0, 1).toUpperCase())
				.join("");
			return inStr.length > 3 ? inStr.slice(0, 3) : inStr;
		}
		return props.name.slice(0, 3).toUpperCase();
	}
	return props.name.toUpperCase() || "";
});

function stringToHslColor(str: string, s = 60, l = 60) {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.codePointAt(i) || 0 + ((hash << 5) - hash);
	}

	const h = hash % 360;
	return "hsl(" + h + ", " + s + "%, " + l + "%)";
}

function resObsCallback(entries: any) {
	// for (const entry of entries) {
	if (!props.size) {
		setupDynamicFontSize();
	}
	// }
}
function setupResObs() {
	if (gravatarWrapRef.value) {
		resizeObserver = useResizeObserver(gravatarWrapRef.value, resObsCallback);
	}
}

function clearResObs() {
	resizeObserver?.stop();
	resizeObserver = null;
}

function setupDynamicFontSize() {
	const parentWid = gravatarWrapRef.value?.offsetWidth;
	if (parentWid) {
		dynamicFontSize.value = `calc(${parentWid}px / 3 + 0.15vw)`;
	} else {
		// console.warn("Invalid parent width", this.$el);
	}
}

onMounted(() => {
	setupResObs();
});
onUnmounted(() => {
	clearResObs();
});
</script>

<template lang="pug">
.gravatar-image(
	ref="gravatarWrapRef",
	:style="getAvatarStyle",
	:title="props.name",
	@click="$emit('click', $event)"
)
	span.text(:style="getTextStyle") {{ parsedInitials }}
</template>

<style lang="scss" scoped>
$text-color: white;

.gravatar-image {
	display: flex;
	border-radius: 50%;
	height: 100%;
	width: 100%;
	padding: 10px;
	aspect-ratio: 1 / 1;
	justify-content: center;
	align-items: center;
	user-select: none;
	font-size: 14px;
	font-weight: bold;
	color: $text-color;

	> * {
		pointer-events: none;
	}
}
</style>
