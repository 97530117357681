<template lang="pug">
ul.context-list
	li(
		v-for="(item, index) in filterHiddenItems",
		:key="index",
		:class="{ disabled: item.isDisabled }",
		@click.prevent.stop="onItemClick($event, item, index)"
	)
		slot(:name="item")
			.label {{ item.label }}
</template>

<script>
// UPDATED: Aug 2021 ---
/*
Items should be:
Events start with "on..."
[
  {
    label: 'Submit',
    isDisabled: false,
    isHidden: false,
    onClick: {FunctionToCallWhenClickedItem}
  }
]
*/
export default {
	props: {
		items: {
			type: Array,
			required: true,
		},
	},
	emits: ["click", "close"],
	computed: {
		filterHiddenItems() {
			const items = this.items;
			if (Array.isArray(items)) {
				return items.filter((item) => !item.isHidden);
			}
			return items;
		},
	},
	mounted() {
		document.addEventListener("mousedown", this.addOutsideClickEvent);
	},
	beforeUnmount() {
		document.removeEventListener("mousedown", this.addOutsideClickEvent);
	},
	methods: {
		onItemClick(evt, item, index) {
			if (item.isDisabled) {
				// Close early
				return;
			}

			const payload = {
				item,
				index,
				evt,
			};
			this.$emit("click", payload);
			item.onClick?.(payload);
		},
		onCloseContextList() {
			this.$emit("close");
		},
		addOutsideClickEvent(evt) {
			const isElementWithin = evt.target.closest(".context-list");
			if (!isElementWithin) {
				this.onCloseContextList();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.context-list {
	display: flex;
	flex-direction: column;
	list-style-type: none;

	li {
		$border-color: #3535355d;

		display: flex;
		width: 100%;
		padding: 0 4px;

		.label {
			border-top: 1px solid $border-color;
			border-bottom: 1px solid $border-color;
			padding: 6px;
			width: 100%;
			height: 100%;
		}

		&.disabled {
			opacity: 0.8;
			cursor: default;
		}

		&:not(.disabled):hover {
			background: #ffffff10;
		}
	}
}
</style>
