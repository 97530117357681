import store from "@/store";

/**
 * @param {any} payload
 * @returns {Promise<boolean>}
 */
export async function autoEnterSiteFunc(payload) {
	/**
	 * @param {string} [message]
	 */
	const openWarningMsg = (message) => {
		const params = {
			data: {
				type: "warning",
				text: message ?? "No site found",
			},
		};
		store.dispatch("moduleToast/openToastStorage", params);
	};

	try {
		// Set project info before entering
		const resProject = await store.dispatch(
			"moduleProject/getProjectData",
			payload.project,
		);
		const projectInfo = resProject.data.data;
		await store.dispatch("moduleProject/setProjectData", projectInfo);

		const responseBool = await store.dispatch(
			"moduleProject/autoEnterSite",
			payload,
		);
		if (responseBool) {
			return true;
		}
		openWarningMsg();
	} catch (err) {
		console.warn(err.message);
		sessionStorage.removeItem("lastProjectSiteID");
		sessionStorage.removeItem("lastProjectID");
		openWarningMsg(err.response?.data?.message);
	}
	return false;
}

/**
 * @param {{ name: string; }} to
 * @param {{}} from
 * @param {any} nextCallback
 */
export async function routeFirstPageProject(to, from, nextCallback) {
	const siteID = sessionStorage.lastProjectSiteID;
	const projectID = sessionStorage.lastProjectID;
	const payload = {
		site: siteID,
		project: projectID,
	};
	if (siteID && projectID) {
		await store.dispatch("moduleMain/setLoading", true);
		const result = await autoEnterSiteFunc(payload);

		if (result === false) {
			if (to.name === "ProjectList") {
				nextCallback();
			} else {
				nextCallback({ name: "ProjectList" });
			}
		} else {
			nextCallback();
		}
	} else {
		nextCallback({ name: "ProjectList" });
		// nextCallback();
	}
	await store.dispatch("moduleMain/setLoading", false);
}
