<template>
	<div class="toast-wrap">
		<div
			v-for="(tfil, filIndex) in toastsFilter"
			:key="filIndex"
			:class="tfil.class"
		>
			<transition-group :name="tfil.transition" class="trans-group" tag="span">
				<toast-comp
					v-for="(toast, index) in tfil.toasts"
					:key="toast.id"
					:index="index"
					:posIndex="posIndex(toast)"
					:toast="toast"
					:posCounts="posCounts"
				/>
			</transition-group>
		</div>
	</div>
</template>

<script>
import ToastComp from "./ToastComp.vue";
import { mapState } from "vuex";
export default {
	components: {
		ToastComp,
	},
	computed: {
		...mapState({
			toasts: (state) => state.moduleToast.toasts,
		}),
		// anyToastActive () {
		//   return Boolean(this.toastsTopLeft.length || this.toastsTopRight.length || this.toastsBottomLeft.length || this.toastsBottomRight.length)
		// },
		toastsTopLeft() {
			return this.toasts.filter((el) => el.data.position === 1);
		},
		toastsTopRight() {
			return this.toasts.filter((el) => el.data.position === 2);
		},
		toastsBottomRight() {
			return this.toasts.filter((el) => ![1, 2, 4].includes(el.data.position));
		},
		toastsBottomLeft() {
			return this.toasts.filter((el) => el.data.position === 4);
		},
		toastsFilter() {
			return [
				{
					class: "tl",
					transition: "slide-fade-left",
					toasts: this.toastsTopLeft,
				},
				{
					class: "tr",
					transition: "slide-fade",
					toasts: this.toastsTopRight,
				},
				{
					class: "br",
					transition: "slide-fade",
					toasts: this.toastsBottomRight,
				},
				{
					class: "bl",
					transition: "slide-fade-left",
					toasts: this.toastsBottomLeft,
				},
			];
		},
		posCounts() {
			const tl = this.toastsTopLeft.length;
			const tr = this.toastsTopRight.length;
			const br = this.toastsBottomRight.length;
			const bl = this.toastsBottomLeft.length;
			return {
				tl,
				tr,
				br,
				bl,
			};
		},
	},
	methods: {
		posIndex(toast) {
			const posArr = this.toasts.filter((el) => {
				if (el.data.position && toast.data.position) {
					return el.data.position === toast.data.position;
				}
				return el;
			});
			return posArr.findIndex((el) => el.id === toast.id);
		},
	},
};
</script>

<style lang="scss" scoped>
.toast-wrap {
	& > div {
		position: fixed;
		z-index: 301;

		& > .trans-group {
			display: flex;
			flex-direction: column;
			gap: 10px;
		}

		&.tl {
			top: 20px;
			left: 20px;

			& > .trans-group {
				flex-direction: column-reverse;
			}
		}

		&.tr {
			top: 20px;
			right: 20px;

			& > .trans-group {
				display: flex;
				flex-direction: column-reverse;
			}
		}

		&.br {
			bottom: 20px;
			right: 20px;
		}

		&.bl {
			bottom: 20px;
			left: 20px;
		}
	}

	@include slideFadeTransition(0.3s, false, 20px);
}
</style>
