<template lang="pug">
.radio-wrap(:class="{ checked: getChecked }")
	.radio-group(@click="changeRefInput")
		span(v-if="getLeftText", :class="options.labelClass") {{ getLeftText }}
		span.radio-input(
			:class="{ checked: getChecked }",
			:tabindex="tabIndexBasedOnDisabled",
			:style="getRadioStyle.outer",
			@keyup.space="changeRefInput"
		)
			span.fill-check(v-if="getChecked", :style="getRadioStyle.inner")
		span(v-if="getRightText", :class="options.labelClass") {{ getRightText }}
	input.hidden(
		ref="input",
		:checked="getChecked",
		:disabled="options.disabled",
		type="checkbox",
		@change="changeVal"
	)
</template>

<script>
// UPDATED: Aug 2021 ---
// RadioInput options:
// [checked, text, rightText, radioSize]
import inputCompMixin from "../inputCompMixin";

export default {
	mixins: [inputCompMixin],
	emits: ["change"],
	computed: {
		getRadioStyle() {
			// radioSize - Size [INT] in px
			const { radioSettings } = this.options;
			const radioSize = radioSettings.size;

			const defaultOuterSize = `${radioSize}px` || "1.2rem";
			const defaultInnerSize = `calc(${radioSize}px - 15px)` || "0.7rem";
			const defaultOuterBack = this.getChecked
				? radioSettings.outerColor
				: "#1f2329";

			const defaultPrimaryColor = getComputedStyle(
				document.body,
			).getPropertyValue("--main-accent-color");
			// const defaultPrimaryColor = "#70b2cc";
			const defaultInnerBack = radioSettings.innerColor || defaultPrimaryColor;

			return {
				outer: {
					background: defaultOuterBack,
					"min-height": defaultOuterSize,
					"min-width": defaultOuterSize,
				},
				inner: {
					background: defaultInnerBack,
					width: defaultInnerSize,
					height: defaultInnerSize,
				},
			};
		},
	},
	methods: {
		changeVal(evt) {
			const isChecked = evt.target.checked;

			if (isChecked === true && this.checked !== true) {
				if (!this.isDirectChecked) {
					this.options.checked = isChecked;
				}

				this.$emit("change", {
					evt,
					checked: isChecked,
				});
			}
			// Cancel if checked false
		},
	},
};
</script>

<style lang="scss" scoped>
.radio-wrap {
	.radio-group {
		display: flex;
		align-items: center;
		gap: 0 5px;

		.radio-input {
			border: thin solid #545454;
			border-radius: 50%;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			position: relative;

			&.checked {
				background: inherit;

				span {
					border-radius: 50%;
					transition: background-color 0.2s ease;
				}
			}
		}

		label {
			user-select: none;
		}
	}

	.hidden {
		visibility: hidden;
		position: absolute;
	}
}
</style>
