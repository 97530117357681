<template>
	<div
		class="toast-comp"
		:class="[parseToastType, { 'has-close': toastData.cancellable }]"
		@click.prevent.stop="clickToast"
		@mouseenter="pauseTimeout()"
		@mouseleave="unpauseTimeout()"
	>
		<!-- <div class="icon">
      <span
        class="fa"
        :class="parseTypeIcon"
      ></span>
    </div> -->
		<div class="icon">
			<inline-svg :src="getImageSrc"></inline-svg>
		</div>
		<div
			v-if="toastData.cancellable"
			class="close"
			@click.prevent.stop="closeToast"
		>
			<span class="fa fa-times"></span>
		</div>
		<div class="text">
			<h4>{{ notifTitle }}</h4>
			<span>{{ notifText }}</span>
		</div>
		<div class="progress-bar">
			<div class="path" :style="getProgressStyles"></div>
		</div>
		<!-- <div class="progress-bar2">{{progressBar}}</div> -->
	</div>
</template>

<script>
// <<< HOW TO USE >>>
// Toast Options example
// {
//    data: {
//      type: 'info' // Default > 'info'
//      duration: 3000 // Duration in ms, default > 5000 | 0 for indefinite
//      position: 1 // 1 - Top Left, 2 - Top Right, 3 - Bottom Right, 4 - Bottom Left // Default > 3
//      title: 'Hello' // Default > Title based on toast type
//      text: 'My message' // Default '',
//      closeOnClick: false // Default > true,
//      cancellable: true // Default > false | Adds a close button for toast that doesn't trigger click event
//    },
//    events: {
//      onclick: () => {} // callback function to invoke on toast click
//      onclose: () => {} // callback function to invoke on toast close > from time or user invoke
//    }
// }
import infoIcon from "@/assets/image/info-icon.svg";
import successIcon from "@/assets/image/success-icon.svg";
import warningIcon from "@/assets/image/warning-icon.svg";
import errorIcon from "@/assets/image/error-icon.svg";
export default {
	props: {
		toast: {
			type: Object,
			required: true,
		},
		index: {
			type: Number,
		},
		posIndex: {
			type: Number,
		},
		posCounts: {
			type: Object,
		},
	},
	data() {
		return {
			durationLeft: 0,
			totalDuration: 0,
			// progressBar: 0,
			startTime: null,
			timeout: null,
		};
	},
	computed: {
		toastData() {
			return this.toast.data;
		},
		toastEvents() {
			return this.toast.events;
		},
		toastOptionData() {
			return {
				info: {
					title: "Info",
					faIcon: "fa-info",
					icon: infoIcon,
				},
				success: {
					title: "Success",
					faIcon: "fa-check",
					icon: successIcon,
				},
				warning: {
					title: "Warning",
					faIcon: "fa-exclamation-triangle",
					icon: warningIcon,
				},
				error: {
					title: "Error",
					faIcon: "fa-times",
					icon: errorIcon,
				},
			};
		},
		parseToastType() {
			const toastType = this.toastData.type;
			if (toastType) {
				return toastType;
			}
			return "";
		},
		parseTypeIcon() {
			return this.toastOptionData[this.toastData?.type]?.faIcon || "fa-info";
		},
		notifTitle() {
			return (
				this.toastData.title ||
				this.toastOptionData[this.toastData?.type]?.title ||
				"Info"
			);
		},
		notifText() {
			return this.toastData.text || "";
		},
		toastDuration() {
			return this.toastData?.duration || 0;
		},
		getImageSrc() {
			return this.toastOptionData[this.toastData?.type].icon;
		},
		calcProgressPerc() {
			const calc = Math.round(
				(1 / this.totalDuration) * this.progressBar * 100,
			);
			return calc;
		},
		getProgressStyles() {
			return {
				// width: `${this.calcProgressPerc}%`,
				animationDuration: `${this.totalDuration}ms`,
			};
		},
	},
	mounted() {
		this.setupToastDuration();
	},
	methods: {
		setTimeout(timeout) {
			if (timeout) {
				clearTimeout(this.timeout);
			}
			this.timeout = timeout;
			this.startTime = Date.now();
		},
		setupToastDuration() {
			const parseSetDur = () => {
				let parsedDuration = 5000;
				if (this.toastData) {
					const dur = parseInt(this.toastData.duration, 10);
					if (!isNaN(dur)) {
						parsedDuration = dur;
					}
				}
				this.totalDuration = parsedDuration;
				this.durationLeft = parsedDuration;
				return parsedDuration;
			};

			const duration = parseSetDur();
			// const onePerc = dur / 100
			// const interval = setInterval(() => {
			//   if (this.progressBar > this.totalDuration) {
			//     clearInterval(interval)
			//   } else {
			//     this.progressBar += onePerc
			//   }
			// }, onePerc);

			// Autoclose setup
			const setupDurationAutoClose = (duration) => {
				if (duration !== 0) {
					return setTimeout(() => {
						this.closeToastStorage(this.toast);
					}, duration);
				}
			};

			const timeout = setupDurationAutoClose(duration);
			if (timeout) {
				this.setTimeout(timeout);
			}
		},
		clickToast() {
			if (this.toastEvents?.onclick) {
				this.toastEvents.onclick(this.toast);
			}

			if (this.toastData.closeOnClick !== false) {
				this.closeToastStorage(this.toast);
			}
		},
		closeToast() {
			this.closeToastStorage(this.toast);
		},
		pauseTimeout() {
			clearTimeout(this.timeout);
			this.timeout = null;

			if (this.startTime) {
				const elapsedDuration = Date.now() - this.startTime;
				this.durationLeft -= elapsedDuration;
			}
		},
		unpauseTimeout() {
			const toast = this.toast;
			const currDur = this.durationLeft;
			if (currDur === 0) {
				// Cancel if infinite
				return;
			}

			const timeout = setTimeout(() => {
				this.closeToastStorage(toast);
			}, this.durationLeft);

			this.setTimeout(timeout);
		},
	},
};
</script>

<style lang="scss" scoped>
$success-color: $green;
$warning-color: #e4e743;
$error-color: #e74370;
$bg-color: #303033;

.toast-comp {
	// position: fixed;
	color: white;
	padding: 20px 15px;
	min-width: 300px;
	max-width: 385px;
	max-height: 130px;
	overflow: hidden;
	z-index: 20;
	position: relative;
	background: $bg-color;
	border-radius: 0;
	border: 1px solid rgba($alt-background, 0.3);
	border-bottom: 0;
	align-items: center;
	cursor: pointer;
	transition: all 0.5s ease;
	display: flex;
	gap: 20px;

	> .icon {
		width: 30px;
		height: 30px;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	> .close {
		position: absolute;
		right: 20px;
		top: 15px;
	}

	> .text {
		display: flex;
		flex-direction: column;
		gap: 5px;
		user-select: none;
		color: #c0c0c0;

		h4 {
			font-weight: 600;
			margin: 0;
			color: white;
		}
	}

	> .progress-bar {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;

		.path {
			animation-name: progressAnimation;
			animation-timing-function: linear;
			animation-iteration-count: 1;
			height: 4px;
			background: white;
			transition: width 0.2s ease;
		}
	}

	&.success {
		> .progress-bar {
			.path {
				background: $success-color;
			}
		}
	}
	&.warning {
		> .progress-bar {
			.path {
				background: $warning-color;
			}
		}
	}
	&.error {
		> .progress-bar {
			.path {
				background: $error-color;
			}
		}
	}

	&:hover {
		background: lighten($color: $bg-color, $amount: 2);

		> .progress-bar {
			.path {
				animation-play-state: paused;
			}
		}
	}

	@keyframes progressAnimation {
		0% {
			width: 0;
		}
		100% {
			width: 100%;
		}
	}

	> .progress-bar2 {
		color: red;
	}

	&.has-close {
		padding-right: 50px;
	}
}
</style>
