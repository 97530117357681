<template>
	<div v-if="data.slot">
		<slot></slot>
	</div>
	<tr v-else>
		<td
			v-for="(val, index) in getDataToShow"
			:key="index"
			:class="val.class"
			@click.stop="checkClick(val, $event)"
		>
			<div
				v-if="isEditable(val)"
				class="editable-wrapper"
				@click.stop="checkClick(val)"
			>
				<input
					readonly
					type="text"
					name
					:value="getCorrespondingCell(val)"
					class="cellInput"
					@contextmenu.prevent="openDefaultContextMenu($event)"
					@blur="blurInput($event, val)"
					@dblclick.stop="activateInput($event, val)"
					@input="changeInputVal($event)"
					@click.stop="checkClick(val, $event)"
				/>
				<i
					class="fas fa-check"
					@click.stop
					@mousedown.stop="saveChanges($event, val)"
				></i>
				<i
					class="fas fa-times"
					@click.stop
					@mousedown.stop="discardChanges($event, val)"
				></i>
			</div>
			<div
				v-else-if="isSlot(val)"
				v-tooltip="getCorrespondingTooltip(val)"
				class="slot"
				v-html="getCorrespondingSlot(val)"
			></div>
			<checkbox-input
				v-else-if="isCheckbox(val)"
				:checked="getCorrespondingCell(val)"
				@change="emitCheckbox($event.evt)"
			/>
			<div
				v-else-if="
					getCorrespondingCell(val) ||
					val.icon ||
					getCorrespondingCell(val) === ''
				"
				class="td-class"
			>
				<i
					v-if="val.icon && getCorrespondingCell(val) !== false"
					:class="`${val.icon}`"
				></i>
				<img
					v-if="val.image && !val.icon && getCorrespondingCell(val) !== false"
					:src="val.image"
					alt=""
				/>
				<span
					v-else-if="!val.icon && !val.image"
					@contextmenu.prevent="openDefaultContextMenu($event)"
					>{{ getCorrespondingCell(val) }}</span
				>
			</div>
		</td>
	</tr>
</template>

<script>
export default {
	name: "TableRow",
	props: {
		data: {
			type: Object,
			required: false,
		},
		columns: {
			type: Array,
			required: false,
		},
		rowIndex: {
			type: Number,
			required: false,
		},
	},
	emits: ["checkboxChanged", "toggleDropdown", "cellSelected", "deleteRow"],
	data() {
		return {
			oldVal: null,
			newVal: null,
			clickable: true,
			clickCounter: 0,
			clickTimer: null,
		};
	},
	computed: {
		getInvislibleCells() {
			const invisibleCells = [];
			for (let i = 0; i < this.columns.length; i++) {
				if (
					!(
						this.columns[i].hasOwnProperty("visibility") &&
						this.columns[i].visibility === false
					)
				) {
					invisibleCells.push(this.columns[i].name);
				}
			}
			return invisibleCells;
		},
		getDataToShow() {
			const colsToShow = [];
			for (let i = 0; i < this.columns.length; i++) {
				if (
					!(
						this.columns[i].hasOwnProperty("visibility") &&
						this.columns[i].visibility === false
					)
				) {
					colsToShow.push(this.columns[i]);
				}
			}
			return colsToShow;
		},
	},
	methods: {
		copyValue(value) {
			navigator.clipboard.writeText(value).then(
				() => {
					this.openToastSuccessMsg("Value copied successfuly");
				},
				(err) => {
					console.log(err);
					this.openToastErrorMsg("Error copying value");
				},
			);
		},
		deleteRow() {
			const row = this.getRowValues();
			const res = {};
			for (const element of row) {
				res[element.name] = element.value;
			}
			const data = {
				row: this.data,
				index: this.rowIndex,
			};
			this.$emit("deleteRow", data);
		},
		getRowValues() {
			const res = [];
			for (let i = 0; i < this.columns.length; i++) {
				for (const variable in this.data.values) {
					if (
						this.data.values.hasOwnProperty(variable) &&
						this.data.values[variable].name === this.columns[i].name &&
						(!this.columns[i].hasOwnProperty("visibility") ||
							this.columns[i].visibility === true)
					) {
						res.push(this.data.values[variable]);
					}
				}
			}
			return res;
		},
		copyRow() {
			const res = this.getRowValues();
			let resStr = "";
			for (let i = 0; i < res.length; i++) {
				const end = res[i + 1] === undefined ? "" : ", ";
				const QuotesIfisNull = res[i].value === null ? "" : "'";
				resStr += QuotesIfisNull + res[i].value + QuotesIfisNull + end;
			}
			this.copyValue(resStr);
		},
		copyRowWithNames() {
			const row = this.getRowValues();
			const res = {};
			for (const element of row) {
				res[element.name] = element.value;
			}
			this.copyValue(JSON.stringify(res));
		},
		openDefaultContextMenu(evt) {
			const contextMenuItems = [
				{
					label: "Copy value",
					onClick: () => {
						this.copyValue(evt.target.value);
					},
				},
				{
					label: "Copy row",
					onClick: () => {
						this.copyRow();
					},
				},
				{
					label: "Copy row (with names)",
					onClick: () => {
						this.copyRowWithNames();
					},
				},
				{
					label: "Delete row",
					onClick: () => {
						this.deleteRow();
					},
				},
			];

			this.openContextMenuStorage({
				data: contextMenuItems,
				evt,
			});
		},
		emitCheckbox(e) {
			this.$emit("checkboxChanged", { val: e.target.checked });
		},
		checkClick(val, e) {
			if (val.hasOwnProperty("editable") && val.editable === true) {
				if (e && e.target.getAttribute("readonly") === null) {
					return;
				}
				this.clickCounter++;
				clearTimeout(this.clickTimer);
				this.clickTimer = setTimeout(() => {
					if (this.clickCounter < 2) {
						if (
							this.data.hasOwnProperty("dropdown") &&
							!val.hasOwnProperty("icon") &&
							!val.hasOwnProperty("image")
						) {
							this.$emit("toggleDropdown", { index: this.rowIndex });
							this.$emit("cellSelected", { val: val });
						} else {
							this.$emit("cellSelected", { val: val });
						}
					}
					this.clickCounter = 0;
				}, 500);
			} else {
				if (
					this.data.hasOwnProperty("dropdown") &&
					!val.hasOwnProperty("icon") &&
					!val.hasOwnProperty("image")
				) {
					this.$emit("toggleDropdown", { index: this.rowIndex });
					this.$emit("cellSelected", { val: val });
				} else {
					this.$emit("cellSelected", { val: val });
				}
			}
		},
		getCorrespondingCell(col) {
			for (const variable in this.data.values) {
				if (
					this.data.values.hasOwnProperty(variable) &&
					this.data.values[variable] &&
					col.name === this.data.values[variable].name
				) {
					if (this.data.values[variable].value === null) {
						return "null";
					}
					return this.data.values[variable].value;
				}
			}
		},
		getCorrespondingSlot(col) {
			for (const variable in this.data.values) {
				if (
					this.data.values.hasOwnProperty(variable) &&
					this.data.values[variable] &&
					col.name === this.data.values[variable].name
				) {
					return this.data.values[variable].hasOwnProperty("slot")
						? this.data.values[variable].slot
						: this.data.values[variable].value;
				}
			}
		},
		getCorrespondingTooltip(col) {
			for (const variable in this.data.values) {
				if (
					this.data.values.hasOwnProperty(variable) &&
					this.data.values[variable] &&
					col.name === this.data.values[variable].name
				) {
					return this.data.values[variable]?.tooltip || "";
				}
			}
			return "";
		},
		isEditable(col) {
			for (let i = 0; i < this.columns.length; i++) {
				if (
					this.columns[i].name === col.name &&
					this.columns[i].hasOwnProperty("editable") &&
					this.columns[i].editable === true
				) {
					return true;
				}
			}
			return false;
		},
		isCheckbox(col) {
			for (let i = 0; i < this.columns.length; i++) {
				if (
					this.columns[i].name === col.name &&
					this.columns[i].hasOwnProperty("checkbox") &&
					this.columns[i].checkbox === true
				) {
					return true;
				}
			}
			return false;
		},
		isSlot(col) {
			for (let i = 0; i < this.columns.length; i++) {
				if (
					this.columns[i].name === col.name &&
					this.columns[i].hasOwnProperty("slot")
				) {
					// console.log(this.columns[i].name);
					return true;
				}
			}
			return false;
		},
		blurInput(e, col) {
			if (
				e.target.classList.contains("active") &&
				this.newVal === this.oldVal
			) {
				e.target.value =
					this.data.values[col.name].value === null
						? "null"
						: this.data.values[col.name].value;
				e.target.classList.remove("active");
				e.target.setAttribute("readonly", "readonly");
			}
		},
		activateInput(e, col) {
			for (const variable in this.data.values) {
				if (
					this.data.values.hasOwnProperty(variable) &&
					variable === col.name
				) {
					this.oldVal = this.data.values[variable].value;
					this.newVal = this.data.values[variable].value;
				}
			}
			if (!e.target.classList.contains("active")) {
				e.target.classList.add("active");
				e.target.removeAttribute("readonly");
			}
		},
		changeInputVal(e) {
			this.newVal = e.target.value;
		},
		saveChanges(e, col) {
			this.clickCounter++;
			if (this.newVal === "") {
				return;
			}
			const res = {
				[col.name]: this.newVal,
				marsColumnsData: this.data,
				marsChangedColumn: col.name,
			};
			this.$emit("saveChanges", res);
			e.target.previousSibling.classList.remove("active");
			e.target.previousSibling.setAttribute("readonly", "readonly");
		},
		discardChanges(e, col) {
			this.clickCounter++;
			e.target.parentElement.firstElementChild.value =
				this.data.values[col.name].value === null
					? "null"
					: this.data.values[col.name].value;
			e.target.parentElement.firstElementChild.classList.remove("active");
			e.target.parentElement.firstElementChild.setAttribute(
				"readonly",
				"readonly",
			);
			const res = {
				[col.name]: this.oldVal,
				marsColumnsData: this.data,
				marsChangedColumn: col.name,
			};
			this.$emit("discardChanges", res);
		},
		checkAndEmit(e, val) {
			this.clickCounter++;
			console.log(e);
		},
	},
};
</script>

<style lang="scss" scoped>
.td-class {
	display: flex;
	flex-direction: row;
	align-items: center;

	// max-width: 200px;
}

tr td {
	color: $dark-silver;
	border-bottom: 1px solid #323741;
}

tr:last-child td {
	border-bottom: none;
}

td:first-child {
	padding-left: 10px;
}

td {
	padding-right: 10px;
}

tr:hover {
	td:first-child {
		border-bottom-left-radius: 8px;
		border-top-left-radius: 8px;
	}

	td:last-child {
		border-bottom-right-radius: 8px;
		border-top-right-radius: 8px;
	}
}

// tbody td span {
//   display: block;
//   height: 0px;
//   overflow: hidden;
// }
tbody td span {
	display: block;
	height: 27px;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 27px;
	word-break: break-all;
}

.fas.fa-list {
	cursor: grab;
}

i {
	margin-left: 8px;
}

.cellInput:not(.active) {
	border: none;
	cursor: pointer;
	width: 100%;
}

.cellInput:not(.active) ~ i {
	display: none;
}

.cellInput {
	padding: 0;
	font-size: 16px;
	color: inherit;
	line-height: 28px;
	background: #0000 !important;

	i {
		margin-left: auto;
	}
}

// new table styles:
tbody {
	tr {
		td {
			.editable-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				position: relative;
			}

			input {
				width: 100%;
				margin: 1px 0;
			}

			span {
				margin: 1px 0;
			}

			.slot {
				display: flex;
				justify-content: center;
			}
		}
	}
}
</style>
