import api from "@/api";
import eventBus from "@/mixins/eventBus.js";
import isDeepEqual from "lodash.isequal";

export default {
	namespaced: true,
	state: {
		// Has to be object always
		userDataObj: {},
	},
	getters: {
		userData: (state) => ({ ...state.userDataObj.user }) || {},
		hasUserData: (state) => Boolean(Object.keys(state.userDataObj).length),
		getActiveBundle: (state, getters) => getters.userData.activeBundle || null,
		getPendingBundle: (state, getters) =>
			getters.userData.pendingBundle || null,
	},
	mutations: {
		SET_USER_DATA_TO_STORE(state, payload) {
			state.userDataObj = payload;
		},
	},
	actions: {
		forgotPass: (store, data) => api.forgotPass(data),
		resetPass: (store, data) => api.resetPass(data),
		async authLogin(store, data) {
			const res = await api.authLogin(data);
			store.dispatch("saveUserToStore", res.data);
			return res;
		},
		registerApi: (store, data) => api.registerApi(data),
		validateToken: (store, data) => api.validateToken(data),
		async expandSession({ dispatch, getters }, payload = {}) {
			const res = await api.expandSession(payload);
			const hasUserData = getters.hasUserData;
			const activeBundleId = getters.getActiveBundle?.use_id;
			await dispatch("saveUserToStore", res.data);
			const newActiveBundleId = getters.getActiveBundle?.use_id;
			if (
				hasUserData &&
				newActiveBundleId &&
				activeBundleId !== newActiveBundleId
			) {
				// Reset expand session
				console.log(
					">> Active bundle changed",
					activeBundleId,
					newActiveBundleId,
				);
				eventBus.$emit("payment-process", false);
			}
			return res;
		},
		saveUserToStore({ state, commit }, data) {
			const isEqual = isDeepEqual(state.userDataObj, data);
			if (!isEqual) {
				const tempObj = { ...state.userDataObj };
				for (const [key, val] of Object.entries(data)) {
					if (
						val &&
						typeof val === "object" &&
						Object.prototype.hasOwnProperty.call(tempObj, key)
					) {
						// This is used to preserve already saved keys
						// For one level object
						for (const [key2, val2] of Object.entries(val)) {
							tempObj[key][key2] = val2;
						}
					} else {
						tempObj[key] = val;
					}
				}
				commit("SET_USER_DATA_TO_STORE", tempObj);
			}
		},
		clearUserData({ commit }) {
			commit("SET_USER_DATA_TO_STORE", {});
		},
	},
};
