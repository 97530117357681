<template>
	<div class="differences-wrapper">
		<uni-tabs
			:tabs="tabs"
			:active-tab="activeTab"
			:can-close-tabs="false"
			:modern-ui="true"
			@tab-clicked="onTabClicked"
		></uni-tabs>
		<div class="content-wrapper">
			<!-- <table-wrapper :database="'source'" :data="getActiveTabData" /> -->
			<table-wrapper :data="getActiveTabData" />
		</div>
		<!-- <div class="sections-wrapper">
      <div v-for="(site, index) in getSiteDifferences" :key="index" class="section">
        <div v-if="site.status === 'OK'" class="ready">
          <div class="section-title-wrapper green">
            <span class="section-title">{{ site.sitAlias }}</span>
            <span class="msg"> ready for transfer!</span>
          </div>
        </div>
        <div v-else class="differences">
          <div class="section-title-wrapper red">
            <span class="section-title">{{ site.sitAlias }}</span>
            <span class="msg"> have differences!</span>
          </div>

        </div>
      </div>
    </div> -->
		<div class="section actions">
			<button class="btn cancel inv" @click="closeModernModalCompat">
				Cancel
			</button>
			<button class="btn apply" @click="beginTransfer">Continue</button>
		</div>
	</div>
</template>

<script>
import UniTabs from "@/modules/shared/uni-tabs/UniTabs.vue";
import tableWrapper from "./tableWrapper.vue";
import { mapGetters } from "vuex";
export default {
	name: "DatabaseDifferences",
	components: {
		tableWrapper,
		UniTabs,
	},
	props: ["options"],
	data() {
		return {
			activeTab: null,
		};
	},
	computed: {
		...mapGetters({
			getSiteData: "moduleProject/getSiteData",
		}),
		getSiteDifferences() {
			return this.options.differences;
		},
		// getSiteAlias() {
		// 	return this.getSiteData.sit_alias;
		// },
		tabs() {
			const res = [];
			for (let i = 0; i < this.options.differences.length; i++) {
				if (this.options.differences[i].status !== "OK") {
					const tab = {
						name: this.options.differences[i].sitAlias,
						label: this.options.differences[i].sitAlias,
						id: i,
						tabData: this.options.differences[i].data,
					};
					res.push(tab);
				}
			}
			return res;
		},
		getActiveTabData() {
			if (this.activeTab) {
				return this.activeTab.tabData;
			}

			return {
				destination: [],
				source: [],
			};
		},
	},
	mounted() {
		console.log(this.options.differences);
		this.activeTab = this.tabs[0];
	},
	methods: {
		beginTransfer() {
			console.log(this.options.continue);
			this.options.continue().then(() => {
				this.closeModernModalCompat();
			});
		},
		onTabClicked(evt) {
			this.activeTab = evt.tab;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/adminModals";
.differences-wrapper {
	@include admin-modals-styles;

	padding-top: 20px;
	height: 100%;

	.tabs {
		margin-left: -10px;
		margin-bottom: 10px;
	}
	.content-wrapper {
		position: relative;
		height: calc(100% - 170px);
		width: 100%;
		padding-right: 10px;
		overflow: auto;
	}
	.sections-wrapper {
		overflow-y: auto;
		max-height: 70vh;
		padding-right: 5px;
		.section {
			margin-bottom: 20px;
		}
	}
	.section-title-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		color: #9ea9c1;
		// border-bottom: 1px solid #ea2f2f;

		.section-title {
			font-size: 20px;
		}
		// .green {
		// , #1e857b)#2da398
		//   color: #2da398;
		// }
		// .red {
		//   color: #ea2f2f;
		// }
	}
	.section-title-wrapper.red {
		border-bottom: 1px solid #ea2f2f;
		.msg {
			color: #ea2f2f;
		}
	}
	.section-title-wrapper.green {
		border-bottom: 1px solid #2da398;
		.msg {
			color: #2da398;
		}
	}
}
</style>
