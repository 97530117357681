<template>
	<div class="modal-wrapper">
		<div class="section">
			<span class="title">Name</span>
			<input v-model="name" type="text" />
		</div>
		<div class="section">
			<span class="title">Path</span>
			<input v-model="path" type="text" />
		</div>
		<div class="section">
			<span class="title">Interval in seconds</span>
			<input v-model.number="interval" type="number" @keyup.enter="addEvent" />
		</div>
		<div class="section actions">
			<button class="btn cancel inv" @click="closeModernModalCompat">
				Cancel
			</button>
			<button class="btn apply" @click="addEvent">Add</button>
		</div>
	</div>
</template>

<script>
import api from "@/api";
export default {
	name: "AddEvent",
	props: ["options"],
	data() {
		return {
			name: "",
			path: "",
			interval: null,
		};
	},
	methods: {
		addEvent() {
			const eventObj = {};
			eventObj.name = "__event_" + this.name;
			eventObj.value = JSON.stringify({
				path: this.path,
				interval: this.interval,
			});

			const params = {
				event: eventObj,
			};
			api.addSiteEvent(params).then((res) => {
				if (res.data.result === "OK") {
					this.openToastSuccessMsg("Event added successfully");
				} else {
					this.openToastErrorMsg("Error adding event");
				}
				this.closeModernModalCompat();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/adminModals";
.modal-wrapper {
	@include admin-modals-styles;
}
.section textarea {
	height: 100px;
	margin-top: 10px;
}
</style>
